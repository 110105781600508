import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate, useParams } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { getTopCategories } from "controllers/CategoryController";
import { getIconsList } from "controllers/Controller";
import { updateExistCategory } from "controllers/CategoryController";
import { getCategory } from "controllers/CategoryController";
import { successMessage } from "utiliz/showMessage";
import { errorMessage } from "utiliz/showMessage";
import { catchMessage } from "utiliz/showMessage";
import config from "configs/config";

let newData = {
  id: "",
  title: "",
  description: "",
  rownumber: 0,
  width: 50,
  category: null,
  adultOnly: false,
  visible: true,
  woolworth: "",
  coles: "",
  products: [],
  categories: [],
  icon:""
};
function UpdateCategory() {
  const [controller] = useMaterialUIController();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(newData);
  const [topc, setTopc] = useState([]);
  const [icons, setIcons] = useState([]);
  const [, setReload] = useState(new Date());
  const navigate = useNavigate();

  const clearForm = () => {
    newData = {
      title: "",
      description: "",
      rownumber: 0,
      icon: "",
      woolworth: "",
      coles: "",
      width: 50,
      category: null,
      visible: true,
      categories: [],
      products: [],
    };
    setFields(newData);
    setReload(new Date());
  };
  const topCategories = async () => {
    try {
      setLoading(true);
      const res = await getTopCategories(controller.token);
      setLoading(false);
      if (res.success) {
        setTopc(res.data);
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const iconList = async () => {
    try {
      setLoading(true);
      const res = await getIconsList(controller.token);
      setLoading(false);
      if (res.success) {
        setIcons(res.data);
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const getCategoryData = async () => {
    try {
      setLoading(true);
      const res = await getCategory(id, controller.token);
      setLoading(false);
      if (res.success) {
        newData.id = id;
        newData.title = res.data.title;
        newData.description = res.data.description;
        newData.rownumber = res.data.rownumber;
        newData.icon = res.data.icon;
        newData.width = res.data.width;
        newData.woolworth = res.data.woolworth;
        newData.coles = res.data.coles;
        newData.adultOnly = res.data.adultOnly;
        newData.category = res.data.category;
        newData.visible = res.data.visible;
        newData.products = res.data.products;
        newData.categories = res.data.categories;
        console.log(res.data);
        setFields(newData);
        setReload(new Date());
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleSubmitClick = async () => {
    try {
      if (!fields.title || !fields.rownumber || !fields.width || !fields.icon) {
        errorMessage("Please fill in the asterisked values");
        return;
      }
      setLoading(true);
      const res = await updateExistCategory(fields, controller.token);
      setLoading(false);
      if (res.success) {
        successMessage(res.message);
        clearForm();
        navigate("/category");
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  useEffect(() => {
    getCategoryData();
    iconList();
    topCategories();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ flexDirection: "row", margin: 0 }}
                  >
                    <MDTypography variant="h6" color="white">
                      edit category
                    </MDTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDButton
                      size="small"
                      style={{ float: "Right" }}
                      variant="contained"
                      onClick={() => {
                        navigate("/category");
                      }}
                    >
                      Back
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox py={3} px={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={12} xs={12}>
                      <TextField
                        id="outlined-basic-rownumber"
                        fullWidth
                        label="Row"
                        required={true}
                        variant="outlined"
                        type="number"
                        value={fields.rownumber}
                        onChange={(event) => {
                          const temp = fields;
                          temp.rownumber = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    mb={1}
                    display={!fields.category ? "none" : "true"}
                  >
                    <Grid item lg={4} md={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="simple-select-label-topcategory">
                          Top Category
                        </InputLabel>
                        <Select
                          id="simple-select-topcategory"
                          labelId="simple-select-label-topcategory"
                          label="Top Category"
                          value={fields.category}
                          onChange={(event) => {
                            const temp = fields;
                            temp.category = event.target.value;
                            setFields(temp);
                            setReload(new Date());
                          }}
                        >
                          <MenuItem value={null}>
                            <em>None</em>
                          </MenuItem>
                          {topc &&
                            topc.map((item) => (
                              <MenuItem value={item.id}>{item.title}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={fields.adultOnly}
                            onChange={() => {
                              const temp = fields;
                              temp.adultOnly = !temp.adultOnly;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        }
                        label="Adult only"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Title"
                        required={true}
                        variant="outlined"
                        value={fields.title}
                        onChange={(event) => {
                          const temp = fields;
                          temp.title = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="simple-select-label-Icon">
                          Icon *
                        </InputLabel>
                        <Select
                          id="simple-select-Icon"
                          labelId="simple-select-label-Icon"
                          label="Top Category"
                          value={fields.icon}
                          onChange={(event) => {
                            const temp = fields;
                            temp.icon = event.target.value;
                            setFields(temp);
                            setReload(new Date());
                          }}
                        >
                          <MenuItem value={null}>
                            <em>None</em>
                          </MenuItem>
                          {icons &&
                            icons.map((item) => (
                              <MenuItem value={item.name}> <img
                              width="30px"
                              height="30px"
                              alt={item.name}
                              src={`${config.publicserver}/png/${item.name}`}
                            /></MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="simple-select-label-width">
                          Box width *
                        </InputLabel>
                        <Select
                          id="simple-select-width"
                          labelId="simple-select-label-width"
                          label="Top Category"
                          value={fields.width}
                          onChange={(event) => {
                            const temp = fields;
                            temp.width = event.target.value;
                            setFields(temp);
                            setReload(new Date());
                          }}
                        >
                          <MenuItem value={null}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="33">33</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="100">100</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Description"
                        required={true}
                        variant="outlined"
                        value={fields.description}
                        multiline
                        minRows={5}
                        onChange={(event) => {
                          const temp = fields;
                          temp.description = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  {fields.category && (
                    <>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={12} md={12} xs={12}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Woolworth page"
                            required={true}
                            variant="outlined"
                            value={fields.woolworth}
                            onChange={(event) => {
                              const temp = fields;
                              temp.woolworth = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={12} md={12} xs={12}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Coles page"
                            required={true}
                            variant="outlined"
                            value={fields.coles}
                            onChange={(event) => {
                              const temp = fields;
                              temp.coles = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        variant="contained"
                        color="info"
                        onClick={() => {
                          handleSubmitClick();
                        }}
                      >
                        Edit
                      </MDButton>
                    </Grid>
                  </Grid>
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UpdateCategory;
