import Axios from "axios";
import config from "../configs/config";

const returnError = (err) => {
  console.log(err);
  const data = {
    success: false,
    message: "There is a problem",
  };
  return data;
};

export const getBrands = async (rowsPerPage, page, search, token) => {
  try {
    const url = `${config.apiserver}/dashboard/brand/getbrands/`;
    const result = await Axios.post(
      url,
      { rowsPerPage, page, search },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getBrand = async (id, token) => {
  try {
    const url = `${config.apiserver}/dashboard/brand/getbrand/${id}`;

    const result = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(url, result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const storeNewBrand = async (newData, image, token) => {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(newData));
    formData.append("image", image);
    const url = `${config.apiserver}/dashboard/brand/storebrand/`;

    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const updateExistBrand = async (newData, image, token) => {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(newData));
    formData.append("image", image);
    const url = `${config.apiserver}/dashboard/brand/updatebrand/`;

    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const deleteBrand = async (id, token) => {
  try {
    const url = `${config.apiserver}/dashboard/brand/brand/${id}`;
    console.log(url);
    const result = await Axios.delete(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
