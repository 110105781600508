/* eslint-disable no-console */
/* eslint-disable no-plusplus */
import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Joi from "joi";
import {
  storeNewProduct,
  uploadImages,
  getProductsForStore,
  getProductForStore,
  getInternationals,
} from "controllers/ProductController";
import { getCategories } from "controllers/CategoryController";
import { getUserStores } from "controllers/StoreController";
import { getBrands } from "controllers/BrandController";
import { FileUploader } from "react-drag-drop-files";
import config from "configs/config";
import { catchMessage } from "utiliz/showMessage";
import { errorMessage } from "utiliz/showMessage";
import { successMessage } from "utiliz/showMessage";
import { dataErrorsMessage } from "utiliz/showMessage";
import { getColesNewProductsForConfirm } from "controllers/ProductWithoutBrandController";

let newData = {
  product: undefined,
  title: "",
  categories: [],
  brand: "",
  enabled: true,
  image: "",
  store: "",
  description: "",
  bonus: "",
  amount: "",
  weight: "",
  length: "",
  width: "",
  height: "",
  international: [],
  maxOrder: "",
  unit: "",
  unitPrice: "",
  price: "",
  adultOnly: false,
  refundable: false,
  rest: "",
  images: [],
  relateds: "",
};

const fileTypes = ["JPEG", "JPG", "PNG", "GIF"];
const UNITS = ["KG", "GR", "Number", "Meter", "Litr", "CC", "100G", "EA"];
function StoreProduct() {
  const [controller] = useMaterialUIController();
  const [categories, setCategories] = useState();
  const [internationals, setInternationals] = useState();
  const [stores, setStores] = useState();
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(newData);
  const [, setReload] = useState(new Date());
  const [selectedImage, setSelectedImage] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const clearForm = () => {
    newData = {
      product: undefined,
      title: "",
      categories: [],
      brand: "",
      enabled: true,
      image: "",
      store: "",
      description: "",
      bonus: "",
      amount: "",
      weight: "",
      length: "",
      width: "",
      height: "",
      international: [],
      maxOrder: "",
      unit: "",
      unitPrice: "",
      price: "",
      adultOnly: false,
      refundable: false,
      rest: "",
      images: [],
      relateds: "",
    };
    setFields(newData);
    setSelectedImage();
    setReload(new Date());
  };

  const getProductDatas = async () => {
    try {
      clearForm();
      setLoading(true);
      const res = await getColesNewProductsForConfirm(
        { id: id },
        controller.token
      );
      setLoading(false);
      //console.log("resspr", res.data);
      if (res.success) {
        console.log(res.data);
        let temp = fields;
        temp.amount = res.data.amount;
        temp.unit = res.data.unit;
        temp.unitPrice = res.data.unitPrice;
        temp.price = res.data.price;
        temp.discount = res.data.discount;
        temp.description = res.data.title;
        temp.title = res.data.title;
        temp.name = res.data.name;
        temp.categories.push(res.data.categories);
        setSelectedImage(res.data.image);
        temp.image = res.data.image;
        setFields(temp);
        console.log(temp);
        setReload(new Date());
      } else {
        errorMessage(res.message);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const validateFields = async () => {
    try {
      const schema = Joi.object({
        title: Joi.string().required().min(2).max(256),
        categories: Joi.array().min(1).required(),
        store: Joi.string().required(),
        brand: Joi.string().required(),
        rest: Joi.number().required(),
        price: Joi.number().required(),
        description: Joi.string().required(),
        amount: Joi.number().required(),
        unit: Joi.string().required(),
        unitPrice: Joi.number().required(),
      });

      const { error } = schema.validate({
        title: fields.title,
        categories: fields.categories,
        store: fields.store,
        brand: fields.brand.id,
        rest: fields.rest,
        price: fields.price,
        description: fields.description,
        amount: fields.amount,
        unit: fields.unit,
        unitPrice: fields.unitPrice,
      });
      if (error) {
        errorMessage(error.details[0].message);
        return false;
      }
      return true;
    } catch (error) {
      setLoading(false);
      catchMessage(error);
      return false;
    }
  };

  const changeHandler = (event) => {
    try {
      if (event.target.files[0]) {
        let fileName = event.target.files[0].name;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        const imageFormats = [
          "jpg",
          "gif",
          "svg",
          "raw",
          "png",
          "jpeg",
          "dng",
          "webp",
        ];
        if (imageFormats.includes(extFile)) {
          setSelectedImage(event.target.files[0]);
        } else {
          errorMessage("Only image files are allowed!");
        }
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const handleStoreChange = (event) => {
    try {
      const temp = fields;
      temp.store = event.target.value;
      setFields(temp);
      setReload(new Date());
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const handleCategoryChange = (event) => {
    try {
      const {
        target: { value },
      } = event;
      const temp = fields;
      temp.categories = typeof value === "string" ? value.split(",") : value;
      setFields(temp);
      setReload(new Date());
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const handleInternationalChange = (event) => {
    try {
      const {
        target: { value },
      } = event;
      const temp = fields;
      temp.international = typeof value === "string" ? value.split(",") : value;
      setFields(temp);
      setReload(new Date());
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const getInternational = async () => {
    try {
      setLoading(true);
      const res = await getInternationals();
      setLoading(false);
      if (res.success) {
        setInternationals(res.data);
      } else {
        errorMessage(res.message, res.code);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const getDatas = async () => {
    try {
      let dataErrors = "";
      setInternationals([]);
      setLoading(true);
      await getInternational();
      const res = await getCategories(100000, 1, "", controller.token);
      setLoading(false);
      if (res.success) {
        const newCategories = [];
        res.data.forEach((item) => {
          newCategories.push({
            id: item.id,
            title: item.title,
            padding: 2,
            color: "#000",
          });
          item.subCategories.forEach((sitem) => {
            newCategories.push({
              id: sitem.id,
              title: sitem.title,
              padding: 20,
              color: "#757575",
            });
          });
        });
        setCategories(newCategories);
      } else {
        dataErrors += `${res.message}<br />`;
      }
      const reqDatas = {
        rowsPerPage: 10000,
        page: 1,
        search: "",
        isConfirm: true, //get confirmed stores for store products
      };
      setLoading(true);
      const resStores = await getUserStores(reqDatas, controller.token);
      setLoading(false);
      console.log("resStores", resStores);
      if (resStores.success) {
        setStores(resStores.data);
        if (resStores.data.length === 1) {
          let temp = fields;
          temp.store = resStores.data[0].id;
          setFields(temp);
          setReload(new Date());
        }
      } else {
        dataErrors += `${resStores.message}<br />`;
      }
      setLoading(true);
      const resrands = await getBrands(100000, 1, "", controller.token);
      setLoading(false);
      if (resrands.success) {
        console.log("resbrands", resrands);
        setBrands(resrands.data);
      } else {
        dataErrors += `${resrands.message}<br />`;
      }
      if (dataErrors !== "") {
        dataErrorsMessage(dataErrors);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const getProductsForStoreList = async (brand, amount, unit) => {
    try {
      let dataErrors = "";
      setProducts();
      setLoading(true);
      const res = await getProductsForStore(
        { brand, amount, unit },
        controller.token
      );
      setLoading(false);
      if (res.success) {
        setProducts(res.data);
      } else {
        dataErrors += `${res.message}\n`;
      }

      if (dataErrors !== "") {
        dataErrorsMessage(dataErrors);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const uploadProductImages = async (file) => {
    try {
      setLoading(true);
      const res = await uploadImages(file, controller.token);
      setLoading(false);
      if (res.success) {
        console.log(res.data, res.image);
        const temp = fields;
        temp.images.push(res.data);

        setFields(temp);
        setReload(new Date());
      }
    } catch (err) {
      setLoading(false);
      catchMessage(err);
    }
  };
  const handleChangeImages = async (files) => {
    try {
      for (let i = 0; i < files.length; i++) {
        uploadProductImages(files[i]);
      }
      setReload(new Date());
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const handleSubmitClick = async () => {
    try {
      let validationResult = await validateFields();
      if (!validationResult) {
        return;
      }
      let temp = fields;
      temp.brand = temp.brand.id;
      setLoading(true);
      const res = await storeNewProduct(temp, selectedImage, controller.token);
      setLoading(false);
      if (res.success) {
        successMessage(res.message);
        clearForm();
      } else {
        errorMessage(res.message, res.code);
        temp = fields
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  useEffect(() => {
    getDatas();
    getProductDatas();
  }, []);
  useEffect(() => {
    if (fields.brand && fields.amount && fields.unit && !id) {
      console.log(fields.brand, fields.amount, fields.unit);
      getProductsForStoreList(fields.brand.id, fields.amount, fields.unit);
    }
  }, [fields.brand.id, fields.amount, fields.unit]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ flexDirection: "row", margin: 0 }}
                  >
                    <MDTypography variant="h6" color="white">
                      New product
                    </MDTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDButton
                      size="small"
                      style={{ float: "Right" }}
                      variant="contained"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Back
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox py={3} px={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <FormControl fullWidth>
                        {brands && (
                          <Autocomplete
                            options={brands}
                            value={fields.brand}
                            onChange={(event, newValue) => {
                              const temp = fields;
                              temp.brand = newValue;
                              setFields(temp);
                              setReload(new Date());
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Brand Name"
                              />
                            )}
                          />
                        )}

                      </FormControl>
                    </Grid>
                  </Grid>
                  {fields.brand && (
                    <Grid container spacing={2} mb={1}>
                      <Grid item lg={4} md={6} xs={10}>
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          type="number"
                          label="the amount of"
                          variant="outlined"
                          value={fields.amount}
                          onChange={(event) => {
                            const temp = fields;
                            temp.amount = event.target.value;
                            if (
                              event.target.value !== "" &&
                              temp.price !== ""
                            ) {
                              const result = temp.price / event.target.value;
                              temp.unitPrice = Math.round(result * 100) / 100;
                            }
                            setFields(temp);
                            setReload(new Date());
                          }}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xs={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-single-unit">Unit</InputLabel>
                          <Select
                            labelId="demo-single-unit"
                            id="demo-single-unit"
                            value={fields.unit}
                            label="Unit"
                            onChange={(event) => {
                              const temp = fields;
                              temp.unit = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          >
                            {UNITS.map((item) => (
                              <MenuItem key={item} value={item} title={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  {fields.amount && fields.unit && fields.brand && (
                    <>
                      <Grid container spacing={2} mb={1}>
                        {products && (
                          <Grid item lg={4} md={6} xs={12}>
                            <Autocomplete
                              id="free-solo-demo"
                              freeSolo
                              value={fields.title}
                              inputValue={fields.title}
                              onInputChange={(event, newInputValue) => {
                                const temp = fields;
                                temp.title = newInputValue;
                                setFields(temp);
                                setReload(new Date());
                              }}
                              onChange={async (event, newValue) => {
                                if (newValue.length > 1) {
                                  const SelectedProduct = products.filter(
                                    (a) => a.title === newValue
                                  );
                                  const temp = fields;
                                  temp.title = newValue;

                                  if (SelectedProduct.length > 0) {
                                    let id = SelectedProduct[0];
                                    id = id.id;
                                    const presult = await getProductForStore(
                                      {
                                        id,
                                      },
                                      controller.token
                                    );
                                    let dataErrors = "";
                                    if (presult.success) {
                                      const p = presult.data.productvariants[0];
                                      console.log("ppppp", p);
                                      temp.product = id;
                                      temp.description = p.description;
                                      temp.bonus = p.bonus;
                                      temp.weight = p.weight;
                                      temp.length = p.length;
                                      temp.width = p.width;
                                      temp.height = p.height;
                                      temp.international = p.international;
                                      temp.maxOrder = p.maxOrder;
                                      temp.unitPrice = p.unitPrice;
                                      temp.price = p.price;
                                      temp.adultOnly = p.adultOnly;
                                      temp.refundable = p.refundable;
                                      temp.rest = p.rest;
                                      temp.images = p.images;
                                      temp.relateds = p.relateds;
                                    } else {
                                      dataErrors += `${presult.message}\n`;
                                    }

                                    if (dataErrors !== "") {
                                      dataErrorsMessage(dataErrors);
                                    }
                                  }
                                  setFields(temp);
                                  setReload(new Date());
                                }
                              }}
                              options={products.map((option) => option.title)}
                              renderInput={(params) => (
                                <TextField {...params} label="Title *" />
                              )}
                            />

                            {/* <Autocomplete
                            disablePortal
                            id="combo-box-Title"
                            options={products}
                            freeSolo
                            getOptionLabel={(option) => option.title}
                            renderOption={(props, option) => (
                              <Box component="li">{option.title}</Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-Title"
                                fullWidth
                                label="Title"
                                variant="outlined"
                                value={fields.title}
                                onChange={(event) => {
                                  const temp = fields;
                                  temp.title = event.target.value;
                                  setFields(temp);
                                  setReload(new Date());
                                }}
                              />
                            )}
                          /> */}
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={12} md={12} xs={12} mt={id ? 1 : 0}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-multiple-chip-label">
                              Categories *
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip"
                              label="Categories *"
                              multiple
                              value={fields.categories}
                              onChange={handleCategoryChange}
                            >
                              {categories &&
                                categories.map((category) => (
                                  <MenuItem
                                    key={category.id}
                                    value={category.id}
                                    title={category.title}
                                    style={{
                                      paddingLeft: category.padding,
                                      color: category.color,
                                    }}
                                  >
                                    {category.title}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {stores?.length > 1 && (
                        <Grid container spacing={2} mb={1}>
                          <Grid item lg={12} md={12} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-multiple-store-label">
                                Store *
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-store-label"
                                id="demo-single-store"
                                label="Store *"
                                value={fields.store}
                                onChange={handleStoreChange}
                              >
                                {stores &&
                                  stores.map((store) => (
                                    <MenuItem
                                      key={store.id}
                                      value={store.id}
                                      title={store.title}
                                    >
                                      {store.title}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={4} md={6} xs={12}>
                          <div className="custom-file">
                            <input
                              type="file"
                              accept="image/*"
                              className="custom-file-input"
                              id="image"
                              name="image"
                              onChange={changeHandler}
                            />
                            <div
                              id="imageStatus"
                              className="custom-file-label"
                              htmlFor="selectedImage"
                            >
                              {selectedImage && selectedImage.name}
                              {!selectedImage && "Select image"}
                            </div>
                          </div>
                        </Grid>
                        {fields.image && (
                          <Grid item lg={4} md={6} xs={12}>
                            <img
                              src={`${fields.image}`}
                              alt={fields.title}
                              height={40}
                              width={60}
                            />
                          </Grid>
                        )}
                      </Grid>
                      {internationals && internationals.length > 0 && (
                        <Grid container spacing={2} mb={1}>
                          <Grid item lg={4} md={4} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-multiple-chip-Internationals">
                                Internationals
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                label="Internationals"
                                multiple
                                value={fields.international}
                                onChange={handleInternationalChange}
                              >
                                {internationals &&
                                  internationals.map((international) => (
                                    <MenuItem
                                      key={international.id}
                                      value={international.id}
                                      title={international.text}
                                    >
                                      {international.text}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={12} md={12} xs={12}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Description *"
                            variant="outlined"
                            value={fields.description}
                            multiline
                            minRows={5}
                            onChange={(event) => {
                              const temp = fields;
                              temp.description = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={4} md={6} xs={12}>
                          <TextField
                            id=""
                            fullWidth
                            type="number"
                            label="Price *"
                            variant="outlined"
                            value={`${fields.price}`}
                            onChange={(event) => {
                              const temp = fields;
                              temp.price = event.target.value;
                              if (event.target.value !== "") {
                                const result = event.target.value / temp.amount;
                                temp.unitPrice = Math.round(result * 100) / 100;
                              }
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>

                        <Grid item lg={4} md={6} xs={12}>
                          <TextField
                            id="unitPrice"
                            fullWidth
                            type="number"
                            label="unitPrice *"
                            variant="outlined"
                            value={`${fields.unitPrice}`}
                            onChange={(event) => {
                              const temp = fields;
                              temp.unitPrice = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={4} md={6} xs={12}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            type="number"
                            label="Number *"
                            variant="outlined"
                            value={`${fields.rest}`}
                            onChange={(event) => {
                              const temp = fields;
                              temp.rest = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xs={6}>
                          <TextField
                            id="outlined-maxOrder"
                            fullWidth
                            type="number"
                            label="max order"
                            variant="outlined"
                            value={`${fields.maxOrder}`}
                            onChange={(event) => {
                              const temp = fields;
                              temp.maxOrder = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xs={6}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            type="number"
                            label="Bonus"
                            variant="outlined"
                            value={`${fields.bonus}`}
                            onChange={(event) => {
                              const temp = fields;
                              temp.bonus = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={3} md={3} xs={6}>
                          <TextField
                            id="outlined-basic-weight"
                            fullWidth
                            type="number"
                            label="weight"
                            variant="outlined"
                            value={`${fields.weight}`}
                            onChange={(event) => {
                              const temp = fields;
                              temp.weight = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xs={6}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            type="number"
                            label="the Length"
                            variant="outlined"
                            value={`${fields.length}`}
                            onChange={(event) => {
                              const temp = fields;
                              temp.length = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xs={6}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            type="number"
                            label="width"
                            variant="outlined"
                            value={`${fields.width}`}
                            onChange={(event) => {
                              const temp = fields;
                              temp.width = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xs={6}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            type="number"
                            label="Height"
                            variant="outlined"
                            value={`${fields.height}`}
                            onChange={(event) => {
                              const temp = fields;
                              temp.height = event.target.value;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={6} md={6} xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked
                                onChange={() => {
                                  const temp = fields;
                                  temp.enabled = !fields.enabled;
                                  setFields(temp);
                                  setReload(new Date());
                                }}
                                value={fields.enabled}
                              />
                            }
                            label="In Stock"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={6} md={6} xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => {
                                  const temp = fields;
                                  temp.refundable = !fields.refundable;
                                  setFields(temp);
                                  setReload(new Date());
                                }}
                                value={fields.refundable}
                              />
                            }
                            label="Refundable"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={6} md={6} xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => {
                                  const temp = fields;
                                  temp.adultOnly = !fields.adultOnly;
                                  setFields(temp);
                                  setReload(new Date());
                                }}
                                value={fields.adultOnly}
                              />
                            }
                            label="Adult only"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={12} md={12} xs={12}>
                          <FileUploader
                            multiple
                            handleChange={handleChangeImages}
                            name="file"
                            types={fileTypes}
                            label="Images"
                            maxSize={0.1}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={12} md={12} xs={12}>
                          {fields.images &&
                            fields.images.map((item) => (
                              <img
                                width="100px"
                                height="100px"
                                alt="cc"
                                src={`${config.apiserver}/uploads/product/${item}`}
                                style={{
                                  width: 100,
                                  height: 100,
                                  borderRadius: 50,
                                  margin: 5,
                                  borderWidth: 1,
                                  borderColor: "#000",
                                }}
                              />
                            ))}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} xs={12}>
                          <MDButton
                            size="small"
                            style={{ float: "Right" }}
                            variant="contained"
                            color="info"
                            onClick={() => {
                              handleSubmitClick();
                            }}
                          >
                            Create new
                          </MDButton>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default StoreProduct;
