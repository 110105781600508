import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Joi from "joi";
import { successMessage } from "utiliz/showMessage";
import { errorMessage } from "utiliz/showMessage";
import { catchMessage } from "utiliz/showMessage";
import { sendMobileVerifyCode } from "controllers/UserController";
import { sendEmailVerifyCode } from "controllers/UserController";
import { verifyPhoneNumber } from "controllers/UserController";
import { verifyEmailAddress } from "controllers/UserController";
import CoverLayout from "../components/CoverLayout";
import { getStoreAdminProfile } from "controllers/UserController";

let newData = {
  id: 0,
  mobileActivationCode: "",
  emailActivationCode: "",
  phone: "",
  email: "",
  countryCode: 0,
};
function UserActivation() {
  const { id } = useParams();
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(newData);
  const [isActivePhone, setIsActivePhone] = useState(false);
  const [isActiveEmail, setIsActiveEmail] = useState(false);
  const [, setReload] = useState(new Date());
  const navigate = useNavigate();
  const clearForm = () => {
    newData = {
      id: 0,
      mobileActivationCode: "",
      emailActivationCode: "",
      phone: "",
      email: "",
      countryCode: 0,
    };
    setFields(newData);
    setReload(new Date());
  };

  const getUserData = async () => {
    try {
      let res;
      setLoading(true);
      if(controller.userRole==="storeadmin"){
        res = await getStoreAdminProfile({ id: id }, controller.token);
      }
      setLoading(false);
      console.log("ressss", res.data);
      if (res.success) {
        newData.id = id;
        newData.phone = res.data.users.phone;
        newData.email = res.data.users.email;
        newData.countryCode = res.data.users.countryCode;
        setFields(newData);
        setIsActiveEmail(res.data.isActiveEmail);
        setIsActivePhone(res.data.isActivePhone);
        setReload(new Date());
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleMobileActivationCodeClick = async () => {
    try {
      setLoading(true);
      const res = await sendMobileVerifyCode({ id: id }, controller.token);
      setLoading(false);
      if (res.success) {
        successMessage(res.message);
        //clearForm();
        // navigate(`/`);
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleEmailActivationCodeClick = async () => {
    try {
      setLoading(true);
      const res = await sendEmailVerifyCode({ id: id }, controller.token);
      setLoading(false);
      if (res.success) {
        successMessage(res.message);
        //clearForm();
        // navigate(`/`);
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        //navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleMobileActivateClick = async () => {
    try {
      const temp = fields;
      temp.id = id;
      setFields(temp);
      setReload(new Date());
      const schema = Joi.object({
        activationCode: Joi.required(),
      });
      const { error } = schema.validate({
        activationCode: fields.mobileActivationCode,
      });
      if (error) {
        errorMessage(error.details[0].message);
        return;
      }
      setLoading(true);
      const res = await verifyPhoneNumber(fields, controller.token);
      setLoading(false);
      if (res.success) {
        successMessage(res.message);
        setIsActivePhone(true);
        clearForm();
        // navigate(`/`);
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleEmailActivateClick = async () => {
    const temp = fields;
    temp.id = id;
    setFields(temp);
    setReload(new Date());
    try {
      const schema = Joi.object({
        activationCode: Joi.required(),
      });
      const { error } = schema.validate({
        activationCode: fields.emailActivationCode,
      });
      if (error) {
        errorMessage(error.details[0].message);
        return;
      }
      setLoading(true);
      const res = await verifyEmailAddress(fields, controller.token);
      setLoading(false);
      if (res.success) {
        successMessage(res.message);
        setIsActiveEmail(true);
        clearForm();
        navigate("/authentication/sign-in", { replace: true });
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            fontSize="13px"
            fontWeight="medium"
            color="white"
            mt={1}
          >
            Activate Your Mobile Phone And Email Address
          </MDTypography>
        </MDBox>
        <MDBox py={3} px={3}>
          <LoadingOverlay active={loading} spinner text="Please wait ...">
            {isActivePhone === false && (
              <Grid container spacing={2} mb={3}>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile Activation Code"
                    type="text"
                    variant="outlined"
                    value={fields.mobileActivationCode}
                    onChange={(event) => {
                      const temp = fields;
                      temp.mobileActivationCode = event.target.value;
                      setFields(temp);
                      setReload(new Date());
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <MDButton
                    size="small"
                    style={{ width: "100%" }}
                    variant="contained"
                    color="info"
                    onClick={() => {
                      handleMobileActivationCodeClick();
                    }}
                  >
                    Send Code Again
                  </MDButton>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <MDButton
                    size="small"
                    variant="contained"
                    style={{ width: "100%" }}
                    color="info"
                    onClick={() => {
                      handleMobileActivateClick();
                    }}
                  >
                    Verify
                  </MDButton>
                </Grid>
              </Grid>
            )}
            {isActivePhone === true && isActiveEmail === false && (
              <Grid container spacing={2} mb={1}>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Email Activation Code"
                    type="text"
                    variant="outlined"
                    value={fields.emailActivationCode}
                    onChange={(event) => {
                      const temp = fields;
                      temp.emailActivationCode = event.target.value;
                      setFields(temp);
                      setReload(new Date());
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <MDButton
                    size="small"
                    style={{ width: "100%" }}
                    variant="contained"
                    color="info"
                    onClick={() => {
                      handleEmailActivationCodeClick();
                    }}
                  >
                    Send Code Again
                  </MDButton>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <MDButton
                    size="small"
                    variant="contained"
                    style={{ width: "100%" }}
                    color="info"
                    onClick={() => {
                      handleEmailActivateClick();
                    }}
                  >
                    Verify
                  </MDButton>
                </Grid>
              </Grid>
            )}
          </LoadingOverlay>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default UserActivation;
