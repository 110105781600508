import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import LoopIcon from "@mui/icons-material/Loop";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { styled } from "@mui/material/styles";
// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Tooltip,
} from "@mui/material";
import TablePaginationActions from "examples/Tables/TablePaginationActions";
import MDButton from "components/MDButton";
import { getCustomers } from "controllers/UserController";
import { errorMessage } from "utiliz/showMessage";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Index() {
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [datas, setDatas] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const navigate = useNavigate();
  let emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  // Avoid a layout jump when reaching the last page with empty rows.
  const getData = async (
    rowsPerPageParam,
    pageParam,
    searchParam,
  ) => {
    setLoading(true);
    setDatas([]);

    const res = await getCustomers(
      {
        rowsPerPage: rowsPerPageParam,
        page: pageParam,
        search: searchParam,
      },
      controller.token
    );
    setLoading(false);

    if (res.success) {
      setDatas(res.data);
      setCount(res.count);
      emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, datas.length - (pageParam - 1) * rowsPerPage);
    } else {
     errorMessage(res.message,res.code);

      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(rowsPerPage, newPage + 1, search);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getData(parseInt(event.target.value, 10), 1, search);
  };
  useEffect(() => {
    // getData(rowsPerPage,1, search);
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ flexDirection: "row", margin: 0 }}
                  >
                    <TextField
                      autoFocus
                      id="search"
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={search}
                      placeholder="search ..."
                      onChange={(event) => {
                        setSearch(event.target.value);
                        if (event.target.value === "") {
                          setPage(0);
                          getData(rowsPerPage, 1, "");
                        }
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          setPage(0);
                          getData(rowsPerPage, 1, search);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDButton
                      size="small"
                      style={{ float: "Right" }}
                      variant="contained"
                      onClick={() => {
                        navigate("/newRider");
                      }}
                    >
                      Create new
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  {datas && (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 500 }}>
                        <TableBody>
                          <TableRow style={{ backgroundColor: "#ccc" }}>
                            <TableCell
                              style={{ width: 40, textAlign: "center" }}
                            >
                              row
                            </TableCell>
                            <TableCell>customer</TableCell>
                            <TableCell
                              style={{ maxWidth: 80, textAlign: "center" }}
                            >
                              menu
                            </TableCell>
                          </TableRow>
                          {
                           datas.map((row, ind) => (
                            <StyledTableRow key={row.id}>
                              <TableCell>
                                {rowsPerPage * page + ind + 1}
                              </TableCell>
                              <TableCell>{row.rider}</TableCell>
                              <TableCell>
                                <Tooltip
                                  key={`Tooltipedit${row.id}`}
                                  title="Edit"
                                  aria-label="edit"
                                  style={{
                                    cursor: "pointer",
                                    width: 20,
                                    height: 20,
                                  }}
                                >
                                  <LoopIcon
                                    color="secondary"
                                    onClick={() => {
                                      navigate(
                                        `/updatecustomer/${row.id}`
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            </StyledTableRow>
                          ))}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 10 * emptyRows }}>
                              <TableCell colSpan={8} />
                            </TableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: count },
                              ]}
                              colSpan={3}
                              count={count}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "Rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  )}
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
