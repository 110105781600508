// react-router-dom components
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
// Market Place React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingOverlay from "react-loading-overlay";
// Authentication layout components
import CoverLayout from "views/authentication/components/CoverLayout";
import Joi from "joi";
// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useState } from "react";
import { register } from "controllers/UserController";
import PasswordStrengthBar from "react-password-strength-bar";
import { errorMessage } from "utiliz/showMessage";
import { successMessage } from "utiliz/showMessage";

const userRole = "superadmin";
function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmitClick = async () => {
    if (email === "" && phone === "") {
      errorMessage(`"Email" or "mobile number" is required`);
      return;
    }
    let schema = Joi.object({
      phone: Joi.string().min(10).max(12),
      name: Joi.string().required().min(5).max(256),
      userRole: Joi.string().required(),
      email: Joi.string().allow("").optional(),
      password: Joi.string().required().min(8).max(256),
    });
    if (email === "" && phone !== "") {
      schema = Joi.object({
        phone: Joi.string().min(10).max(12),
        name: Joi.string().required().min(5).max(256),
        userRole: Joi.string().required(),
        email: Joi.string().allow("").optional(),
        password: Joi.string().required().min(8).max(256),
      });
    }
    if (email !== "" && phone === "") {
      schema = Joi.object({
        email: Joi.string().email({ tlds: { allow: false } }),
        name: Joi.string().required().min(5).max(256),
        userRole: Joi.string().required(),
        phone: Joi.string().allow("").optional(),
        password: Joi.string().required().min(8).max(256),
      });
    }

    const { error } = schema.validate({
      name,
      email,
      phone,
      userRole,
      password,
    });

    if (error) {
      errorMessage(error.details[0].message);
      return;
    }
    setLoading(true);
    const newUser = {
      name,
      email,
      phone,
      userRole: "cityadmin",
      password,
    };
    const res = await register(newUser);
    setLoading(false);
    if (res.success) {
      successMessage(res.message);
      navigate("/authentication/sign-in", { replace: true });
      // clearForm();
    } else {
      errorMessage(res.message, res.code);
    }
    if (res.code === 401) {
      navigate("/", { replace: true });
    }
  };
  
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email/mobile number and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <LoadingOverlay active={loading} spinner text="Please wait ...">
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Name"
                  variant="standard"
                  fullWidth
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="tel"
                  label="Mobile number"
                  variant="standard"
                  fullWidth
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
                <PasswordStrengthBar password={password} />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;I agree the&nbsp;
                </MDTypography>
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  Terms and Conditions
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => {
                    handleSubmitClick();
                  }}
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MDButton
                    onClick={() => {
                      navigate("/authentication/store-sign-in");
                    }}
                  >
                    Sign In
                  </MDButton>
                </MDTypography>
              </MDBox>
            </LoadingOverlay>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Register;
