import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Joi from "joi";
import { uploadImages, getInternationals } from "controllers/ProductController";
import { getCategories } from "controllers/CategoryController";
import { getUserStores } from "controllers/StoreController";
import { getBrands } from "controllers/BrandController";
import { FileUploader } from "react-drag-drop-files";
import config from "configs/config";
import { errorMessage } from "utiliz/showMessage";
import { catchMessage } from "utiliz/showMessage";
import { successMessage } from "utiliz/showMessage";
import { dataErrorsMessage } from "utiliz/showMessage";
import { deleteProductWithoutBrand } from "controllers/ProductWithoutBrandController";
import { storeNewProduct } from "controllers/ProductController";
import { getProductWithoutBrands } from "controllers/ProductWithoutBrandController";
import { getProductForStoreWithoutBrand } from "controllers/ProductWithoutBrandController";
import { updateProductsWithoutBrand } from "controllers/ProductWithoutBrandController";
import { getProductsForStore } from "controllers/ProductController";

let newData = {
  product: "",
  title: "",
  categories: [],
  brand: "",
  image: "",
  store: "",
  description: "",
  bonus: "",
  amount: "",
  weight: "",
  length: "",
  width: "",
  height: "",
  international: [],
  maxOrder: "",
  unit: "",
  unitPrice: "",
  price: "",
  adultOnly: false,
  refundable: false,
  rest: "",
  images: [],
  relateds: "",
};

const fileTypes = ["JPEG", "JPG", "PNG", "GIF"];
const UNITS = ["KG", "GR", "Number", "Meter", "Litr", "CC", "100G", "EA"];

function UpdateProductWithoutBrand() {
  const { dId, id } = useParams();
  const [controller] = useMaterialUIController();
  const [categories, setCategories] = useState();
  const [internationals, setInternationals] = useState();
  const [stores, setStores] = useState();
  const [brands, setBrands] = useState();
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(newData);
  const [, setReload] = useState(new Date());
  const [selectedImage, setSelectedImage] = useState();

  const navigate = useNavigate();
  const clearForm = () => {
    newData = {
      variantId: undefined,
      product: undefined,
      title: "",
      categories: [],
      brand: "",
      image: "",
      store: "",
      description: "",
      bonus: "",
      amount: "",
      weight: "",
      length: "",
      width: "",
      height: "",
      international: [],
      maxOrder: "",
      unit: "",
      unitPrice: "",
      price: "",
      adultOnly: false,
      refundable: false,
      rest: "",
      images: [],
      relateds: "",
    };
    setFields(newData);
    setSelectedImage();
    setReload(new Date());
  };

  const changeHandler = (event) => {
    try {
      setSelectedImage(event.target.files[0]);
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const handleStoreChange = (event) => {
    try {
      const temp = fields;
      temp.store = event.target.value;
      setFields(temp);
      setReload(new Date());
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const getProductsForStoreList = async (brand, amount, unit) => {
    try {
      let dataErrors = "";
      setProducts();
      setLoading(true);
      const res = await getProductsForStore(
        { brand, amount, unit },
        controller.token
      );
      setLoading(false);
      if (res.success) {
        setProducts(res.data);
      } else {
        dataErrors += `${res.message}\n`;
      }

      if (dataErrors !== "") {
        dataErrorsMessage(dataErrors);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleInternationalChange = (event) => {
    try {
      const {
        target: { value },
      } = event;
      const temp = fields;
      temp.international = typeof value === "string" ? value.split(",") : value;
      setFields(temp);
      setReload(new Date());
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleCategoryChange = (event) => {
    try {
      const {
        target: { value },
      } = event;
      const temp = fields;
      temp.categories = typeof value === "string" ? value.split(",") : value;
      setFields(temp);
      setReload(new Date());
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const getInternational = async () => {
    try {
      setLoading(true);
      const res = await getInternationals();
      setLoading(false);
      if (res.success) {
        setInternationals(res.data);
      } else {
        errorMessage(res.message, res.code);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const getCategoriess = async () => {
    try {
      setLoading(true);
      const res = await getCategories(100000, 1, "", controller.token);
      setLoading(false);
      if (res.success) {
        const newCategories = [];
        res.data.forEach((item) => {
          newCategories.push({
            id: item.id,
            title: item.title,
            padding: 2,
            color: "#000",
          });
          item.subCategories.forEach((sitem) => {
            newCategories.push({
              id: sitem.id,
              title: sitem.title,
              padding: 20,
              color: "#757575",
            });
          });
        });
        setCategories(newCategories);
      } else {
        errorMessage(res.message);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const getUserStore = async () => {
    try {
      const reqDatas = {
        rowsPerPage: 10000,
        page: 1,
        search: "",
        isConfirm: true, //get confirmed stores for store products
      };
      setLoading(true);
      const res = await getUserStores(reqDatas, controller.token);
      if (res.success) {
        setStores(res.data);
      } else {
        errorMessage(res.message);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const getBrand = async () => {
    try {
      const res = await getBrands(100000, 1, "", controller.token);
      setLoading(false);
      if (res.success) {
        setBrands(res.data);
      } else {
        errorMessage(res.message);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const getDatas = async () => {
    try {
      let dataErrors = "";
      setInternationals([]);
      getCategoriess();
      getUserStore();
      getBrand();
      getInternational();

      if (id) {
        const resProduct = await getProductForStoreWithoutBrand(
          {
            id,
          },
          controller.token
        );
        console.log(resProduct.data);
        if (resProduct.success) {
          newData.product = resProduct.data.product;
          newData.brand = resProduct.data.brand;
          newData.amount = resProduct.data.amount;
          newData.unit = resProduct.data.unit;
          newData.title = resProduct.data.title;
          newData.categories = resProduct.data.categories;
          newData.store = resProduct.data.store;
          newData.description = resProduct.data.description;
          newData.price = resProduct.data.price;
          newData.unitPrice = resProduct.data.unitPrice;
          newData.rest = resProduct.data.rest;
          newData.maxOrder = resProduct.data.maxOrder;
          newData.bonus = resProduct.data.bonus;
          newData.weight = resProduct.data.weight;
          newData.length = resProduct.data.length;
          newData.width = resProduct.data.width;
          newData.height = resProduct.data.height;
          newData.international = resProduct.data.international;
          newData.adultOnly = resProduct.data.adultOnly;
          newData.refundable = resProduct.data.refundable;
          newData.images = resProduct.data.images;
          newData.relateds = resProduct.data.relateds;
          setFields(newData);
          setReload(new Date());
        }
        if (dataErrors !== "") {
          dataErrorsMessage(dataErrors);
        }
      } else {
        const resProduct = await getProductWithoutBrands(
          { id: dId },
          controller.token
        );
        console.log("resspr", resProduct.data);
        if (resProduct.success) {
          newData.amount = resProduct.data.amount;
          newData.unit = resProduct.data.unit;
          newData.title = resProduct.data.title;
          newData.categories = resProduct.data.categories;
          newData.store = resProduct.data.store;
          newData.description = resProduct.data.description;
          newData.price = resProduct.data.price;
          newData.unitPrice = resProduct.data.unitPrice;
          newData.image = resProduct.data.image;
          setFields(newData);
          setReload(new Date());
        }
        if (dataErrors !== "") {
          dataErrorsMessage(dataErrors);
        }
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
      console.log("errrrrr", error);
    }
  };
  const uploadProductImages = async (file) => {
    try {
      setLoading(true);
      const res = await uploadImages(file, controller.token);
      setLoading(false);
      if (res.success) {
        const temp = fields;
        temp.images.push(res.data);
        setFields(temp);
        setReload(new Date());
      }
    } catch (err) {
      setLoading(false);
      catchMessage(err);
    }
  };

  const handleChangeImages = async (files) => {
    try {
      for (let i = 0; i < files.length; i++) {
        uploadProductImages(files[i]);
      }

      setReload(new Date());
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleSubmitClick = async () => {
    try {
      const schema = Joi.object({
        title: Joi.string().required().min(2).max(256),
        categories: Joi.array().min(1).required(),
        store: Joi.string().required(),
        brand: Joi.string().required(),
        rest: Joi.required(),
        price: Joi.required(),
      });
      const { error } = schema.validate({
        title: fields.title,
        categories: fields.categories,
        store: fields.store,
        brand: fields.brand,
        rest: fields.rest,
        price: fields.price,
      });
      if (error) {
        errorMessage(error.details[0].message);
        return;
      }
      if (id) {
        setLoading(true);
        console.log(fields);
        const res = await updateProductsWithoutBrand(
          fields,
          selectedImage,
          controller.token
        );
        setLoading(false);
        if (res.success) {
          await deleteProductWithoutBrand({ id: dId });
          successMessage(res.message);
          clearForm();
          navigate(-1);
        } else {
          errorMessage(res.message, res.code);
        }
        if (res.code === 401) {
          navigate("/", { replace: true });
        }
      } else {
        setLoading(true);
        const res = await storeNewProduct(
          fields,
          selectedImage,
          controller.token
        );
        setLoading(false);
        if (res.success) {
          await deleteProductWithoutBrand({ id: dId });
          successMessage(res.message);
          clearForm();
        } else {
          errorMessage(res.message, res.code);
        }
        if (res.code === 401) {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  useEffect(() => {
    getDatas();
  }, []);
  useEffect(() => {
    if (fields.brand && fields.amount && fields.unit) {
      getProductsForStoreList(fields.brand, fields.amount, fields.unit);
    }
  }, [fields.brand, fields.amount, fields.unit]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ flexDirection: "row", margin: 0 }}
                  >
                    <MDTypography variant="h6" color="white">
                      Update product
                    </MDTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDButton
                      size="small"
                      style={{ float: "Right" }}
                      variant="contained"
                      onClick={() => {
                        clearForm();
                        navigate("/newproducts");
                      }}
                    >
                      Back
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox py={3} px={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Brand</InputLabel>
                        <Select
                          label="Brand"
                          value={fields.brand}
                          onChange={(event) => {
                            const temp = fields;
                            temp.brand = event.target.value;
                            setFields(temp);
                            setReload(new Date());
                          }}
                        >
                          {brands &&
                            brands.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                title={item.title}
                              >
                                {item.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={10}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        type="number"
                        label="the amount of"
                        variant="outlined"
                        value={fields.amount}
                        onChange={(event) => {
                          const temp = fields;
                          temp.amount = event.target.value;
                          if (event.target.value !== "" && temp.price !== "") {
                            const result = temp.price / event.target.value;
                            temp.unitPrice = Math.round(result * 100) / 100;
                          }
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-single-unit">Unit</InputLabel>
                        <Select
                          labelId="demo-single-unit"
                          id="demo-single-unit"
                          value={fields.unit}
                          label="Unit"
                          onChange={(event) => {
                            const temp = fields;
                            temp.unit = event.target.value;
                            setFields(temp);
                            setReload(new Date());
                          }}
                        >
                          {UNITS.map((item) => (
                            <MenuItem key={item} value={item} title={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {products && (
                    <Grid container spacing={2} mb={1}>
                      <Grid item lg={4} md={6} xs={12}>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          value={fields.title}
                          inputValue={fields.title}
                          onInputChange={(event, newInputValue) => {
                            const temp = fields;
                            temp.title = newInputValue;
                            setFields(temp);
                            setReload(new Date());
                          }}
                          onChange={async (event, newValue) => {
                            if (newValue.length > 1) {
                              const temp = fields;
                              temp.title = newValue;
                              setFields(temp);
                              setReload(new Date());
                            }
                          }}
                          options={products.map((option) => option.title)}
                          renderInput={(params) => (
                            <TextField {...params} label="Title" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {fields.categories && (
                    <Grid container spacing={2} mb={1}>
                      <Grid item lg={12} md={12} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-chip-label">
                            Categories
                          </InputLabel>
                          <Select
                            label="Categories"
                            multiple
                            value={fields.categories}
                            onChange={handleCategoryChange}
                          >
                            {categories &&
                              categories.map((category) => (
                                <MenuItem
                                  key={category.id}
                                  value={category.id}
                                  title={category.title}
                                  style={{
                                    paddingLeft: category.padding,
                                    color: category.color,
                                  }}
                                >
                                  {category.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Store</InputLabel>
                        <Select
                          label="Store"
                          value={fields.store}
                          onChange={handleStoreChange}
                        >
                          {stores &&
                            stores.map((store) => (
                              <MenuItem
                                key={store.id}
                                value={store.id}
                                title={store.title}
                              >
                                {store.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="image"
                          name="image"
                          onChange={changeHandler}
                        />
                        <div
                          id="imageStatus"
                          className="custom-file-label"
                          htmlFor="selectedImage"
                        >
                          {selectedImage && selectedImage.name}
                          {!selectedImage && "Select image"}
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={2} md={2} xs={12}>
                      <img
                        src={
                          fields.image.includes("http")
                            ? fields.image
                            : `${config.fileserver}/product/${fields.image}`
                        }
                        height={40}
                        Alt={newData.title}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={fields.description}
                        multiline
                        minRows={5}
                        onChange={(event) => {
                          const temp = fields;
                          temp.description = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        id=""
                        fullWidth
                        type="number"
                        label="Price"
                        variant="outlined"
                        value={`${fields.price}`}
                        onChange={(event) => {
                          const temp = fields;
                          temp.price = event.target.value;
                          if (event.target.value !== "") {
                            const result = event.target.value / temp.amount;
                            temp.unitPrice = Math.round(result * 100) / 100;
                          }
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        id="unitPrice"
                        fullWidth
                        type="number"
                        label="unitPrice"
                        variant="outlined"
                        value={`${fields.unitPrice}`}
                        onChange={(event) => {
                          const temp = fields;
                          temp.unitPrice = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        type="number"
                        label="Number"
                        variant="outlined"
                        value={`${fields.rest}`}
                        onChange={(event) => {
                          const temp = fields;
                          temp.rest = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={6}>
                      <TextField
                        id="outlined-maxOrder"
                        fullWidth
                        type="number"
                        label="max order"
                        variant="outlined"
                        value={`${fields.maxOrder}`}
                        onChange={(event) => {
                          const temp = fields;
                          temp.maxOrder = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={6}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        type="number"
                        label="Bonus"
                        variant="outlined"
                        value={`${fields.bonus}`}
                        onChange={(event) => {
                          const temp = fields;
                          temp.bonus = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={3} md={3} xs={6}>
                      <TextField
                        id="outlined-basic-weight"
                        fullWidth
                        type="number"
                        label="weight"
                        variant="outlined"
                        value={`${fields.weight}`}
                        onChange={(event) => {
                          const temp = fields;
                          temp.weight = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        type="number"
                        label="the Length"
                        variant="outlined"
                        value={`${fields.length}`}
                        onChange={(event) => {
                          const temp = fields;
                          temp.length = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        type="number"
                        label="width"
                        variant="outlined"
                        value={`${fields.width}`}
                        onChange={(event) => {
                          const temp = fields;
                          temp.width = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        type="number"
                        label="Height"
                        variant="outlined"
                        value={`${fields.height}`}
                        onChange={(event) => {
                          const temp = fields;
                          temp.height = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={6} md={6} xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={fields.refundable}
                            onChange={() => {
                              const temp = fields;
                              temp.refundable = !fields.refundable;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        }
                        label="Refundable"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={6} md={6} xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={fields.adultOnly}
                            onChange={() => {
                              const temp = fields;
                              temp.adultOnly = !fields.adultOnly;
                              setFields(temp);
                              setReload(new Date());
                            }}
                          />
                        }
                        label="Adult only"
                      />
                    </Grid>
                  </Grid>
                  {internationals && (
                    <Grid container spacing={2} mb={1}>
                      <Grid item lg={6} md={6} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-chip-Internationals">
                            Internationals
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-chip-label"
                            label="Internationals"
                            id="demo-multiple-chip"
                            multiple
                            value={fields.international}
                            onChange={handleInternationalChange}
                          >
                            {internationals &&
                              internationals.map((international) => (
                                <MenuItem
                                  key={international.id}
                                  value={international.id}
                                  title={international.text}
                                >
                                  {international.text}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <FileUploader
                        multiple
                        handleChange={handleChangeImages}
                        name="file"
                        types={fileTypes}
                        label="Images"
                        maxSize={0.1}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      {fields.images &&
                        fields.images.map((item) => (
                          <img
                            width="100px"
                            height="100px"
                            alt="cc"
                            src={`${config.apiserver}/uploads/product/${item}`}
                            style={{
                              width: 100,
                              height: 100,
                              borderRadius: 50,
                              margin: 5,
                              borderWidth: 1,
                              borderColor: "#000",
                            }}
                          />
                        ))}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        variant="contained"
                        color="info"
                        onClick={() => {
                          handleSubmitClick();
                        }}
                      >
                        {id ? "Update" : "Create new"}
                      </MDButton>
                      <MDButton
                        size="small"
                        style={{ float: "Right", marginRight: 10 }}
                        variant="contained"
                        onClick={() => {
                          navigate(`/products`);
                        }}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                  </Grid>
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default UpdateProductWithoutBrand;
