import Swal from "sweetalert2";

export const successMessage = (message) => {
  return Swal.fire({
    html: message,
    icon: "success",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    position: "bottom-right",
    imageHeight: 50,
  });
};

export const errorMessage = (message, code) => {
  return Swal.fire({
    html: code ? `${message} (${code})` : `${message}`,
    icon: "error",
    confirmButtonText: "OK",
  });
};

export const catchMessage = (error) => {
  console.log("catch error :", error);
  return Swal.fire({
    text: `The operation failed (501)`,
    icon: "error",
    confirmButtonText: "OK",
  });
};

export const dataErrorsMessage = (dataErrors) => {
  return Swal.fire({
    html: dataErrors,
    icon: "error",
    confirmButtonText: "OK",
  });
};

export const deleteMessage =async () => {
  return Swal.fire({
    title: "",
    icon: "warning",
    text: "Do you want to delete?",
    showCloseButton: false,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    }
  });
};

export const confirmMessage =async (message) => {
  return Swal.fire({
    title: "",
    icon: "warning",
    text: message,
    showCloseButton: false,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    }
  });
};