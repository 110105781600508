import Axios from "axios";
import config from "../configs/config";

const returnError = (err) => {
  console.log(err);
  const data = {
    success: false,
    message: "There is a problem",
  };
  return data;
};

export const getProductWithoutBrands = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/getProductWithoutBrand/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getProductsWithoutBrands = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/getProductsWithoutBrand/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const deleteProductWithoutBrand = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/deleteProductWithoutBrand/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getProductForStoreWithoutBrand = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/getProductForStoreWithoutBrand/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateProductsWithoutBrand = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/updateProductsWithoutBrand/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getColesNewProducts = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/getColesNewProducts/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getColesNewProductsForConfirm = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/getColesNewProductsForConfirm/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const storeColesProductVariant = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/storeColesProductVariant/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const returnColesProductVariant = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/returnColesProductVariant/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getNewVariants = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/productWithoutBrand/getNewVariants/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
