import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import LoopIcon from "@mui/icons-material/Loop";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { styled } from "@mui/material/styles";
// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { getActiveCountries } from "controllers/CountryController";
import { getActiveStates } from "controllers/StateController";
import { getTaxFee } from "controllers/Tax_feeController";
import TablePaginationActions from "examples/Tables/TablePaginationActions";
import MDButton from "components/MDButton";
import { errorMessage } from "utiliz/showMessage";
import { dataErrorsMessage } from "utiliz/showMessage";
import { catchMessage } from "utiliz/showMessage";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Index() {
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [datas, setDatas] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [countries, setCountries] = useState();
  const [country, setCountry] = useState();
  const [states, setStates] = useState();
  const [state, setState] = useState();

  const navigate = useNavigate();
  let emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  // Avoid a layout jump when reaching the last page with empty rows.
  const getData = async (
    rowsPerPageParam,
    pageParam,
    countryParam,
    stateParam
  ) => {
    try {
      setLoading(true);
      setDatas([]);
      const res = await getTaxFee(
        {
          rowsPerPage: rowsPerPageParam,
          page: pageParam,
          country: countryParam,
          state: stateParam,
        },
        controller.token
      );
      setLoading(false);
      if (res.success) {
        setDatas(res.data);
        setCount(res.count);
        emptyRows =
          rowsPerPage -
          Math.min(rowsPerPage, datas.length - (pageParam - 1) * rowsPerPage);
      } else {
        errorMessage(res.message, res.code);
        if (res.code === 401) {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const getState = async (countryParam) => {
    try {
      let dataErrors = "";
      setLoading(true);
      const resState = await getActiveStates(countryParam, controller.token);
      setLoading(false);
      if (resState.success) {
        setStates(resState.data);
        if (resState.data.length > 0) {
          setState(resState.data[0].id);
          setLoading(true);
          getData(10, 1, countryParam, resState.data[0].id);
          setLoading(false);
        }
      } else {
        dataErrors += `${resState.message}<br />`;
      }
      if (dataErrors !== "") {
        dataErrorsMessage(dataErrors);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const getCountriesData = async () => {
    try {
      let dataErrors = "";
      setLoading(true);
      const resCountry = await getActiveCountries();
      setLoading(false);
      if (resCountry.success) {
        setCountries(resCountry.data);
        if (resCountry.data.length > 0) {
          setCountry(resCountry.data[0].id);
          setLoading(true);
          getState(resCountry.data[0].id);
          getData(10, 1, resCountry.data[0].id);
          setLoading(false);
        }
      } else {
        dataErrors += `${resCountry.message}<br />`;
      }

      if (dataErrors !== "") {
        dataErrorsMessage(dataErrors);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(rowsPerPage, newPage + 1, country, state);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getData(parseInt(event.target.value, 10), 1, country, state);
  };
  useEffect(() => {
    if (controller.useRole === "cityadmin") {
      getData(10, 1, 1, 1);
    } else {
      getCountriesData();
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <MDButton
                      size="small"
                      style={{ float: "Right" }}
                      variant="contained"
                      onClick={() => {
                        navigate("/newtaxfee");
                      }}
                    >
                      Create new
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  {controller.userRole === "superadmin" && (
                    <Grid container spacing={2} mb={1} pr={1} pl={1}>
                      {countries && countries.length > 0 && country && (
                        <Grid item md={4} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-multiple-store-label">
                              Country
                            </InputLabel>
                            <Select
                              labelId="label-country"
                              label="Country"
                              id="Country"
                              value={country}
                              onChange={(event) => {
                                setCountry(event.target.value);
                                getState(event.target.value);
                              }}
                            >
                              {countries.map((countryItem) => (
                                <MenuItem
                                  key={countryItem.id}
                                  value={countryItem.id}
                                  title={countryItem.name}
                                >
                                  {countryItem.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {states && states.length > 0 && state && (
                        <Grid item md={4} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-multiple-store-label">
                              State
                            </InputLabel>
                            <Select
                              labelId="label-country"
                              label="Country"
                              id="State"
                              value={state}
                              onChange={(event) => {
                                setState(event.target.value);
                                getData(
                                  rowsPerPage,
                                  1,
                                  country,
                                  event.target.value
                                );
                              }}
                            >
                              {states &&
                                states.map((stateItem) => (
                                  <MenuItem
                                    key={stateItem.id}
                                    value={stateItem.id}
                                    title={stateItem.name}
                                  >
                                    {stateItem.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {datas && (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 500 }}>
                        <TableBody>
                          <TableRow style={{ backgroundColor: "#ccc" }}>
                            <TableCell
                              style={{ width: 40, textAlign: "center" }}
                            >
                              row
                            </TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Tax</TableCell>
                            <TableCell>Fee</TableCell>
                            <TableCell
                              style={{ maxWidth: 80, textAlign: "center" }}
                            >
                              menu
                            </TableCell>
                          </TableRow>
                          {datas?.map((row, ind) => (
                            <StyledTableRow key={row.id}>
                              <TableCell>
                                {rowsPerPage * page + ind + 1}
                              </TableCell>
                              <TableCell>{row.cityName}</TableCell>
                              <TableCell>{row.tax}</TableCell>
                              <TableCell>{row.fee}</TableCell>
                              <TableCell>
                                <Tooltip
                                  key={`Tooltipedit${row.id}`}
                                  title="Edit"
                                  aria-label="edit"
                                  style={{
                                    cursor: "pointer",
                                    width: 20,
                                    height: 20,
                                  }}
                                >
                                  <LoopIcon
                                    color="secondary"
                                    onClick={() => {
                                      navigate(
                                        `/updatetaxfee/${row.id}/${row.city}`
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            </StyledTableRow>
                          ))}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 10 * emptyRows }}>
                              <TableCell colSpan={8} />
                            </TableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: count },
                              ]}
                              colSpan={3}
                              count={count}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "Rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  )}
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
