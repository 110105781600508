import Axios from "axios";
import config from "../configs/config";
import woolworth from "./woolworth.json";

const returnError = (err) => {
  console.log(err);
  const data = {
    success: false,
    code: 5001,
    message: "There is a problem",
  };
  return data;
};

export const getStores = async (rowsPerPage, page, search, token) => {
  try {
    const url = `${config.apiserver}/dashboard/store/getstores/`;
    const result = await Axios.post(
      url,
      { rowsPerPage, page, search },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getSupeStoresList = async (token) => {
  try {
    const url = `${config.apiserver}/dashboard/store/getsuperstores/`;
    const result = await Axios.post(url, null, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getUserStores = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/store/getuserstores/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getStore = async (id, token) => {
  try {
    const url = `${config.apiserver}/dashboard/store/getstore/${id}`;

    const result = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(url, result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getStoreForStorAdmin = async (token) => {
  try {
    const url = `${config.apiserver}/dashboard/store/getstoreforstoradmin/`;

    const result = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const storeNewStore = async (newData, image, token) => {
  try {
    console.log(newData);
    const formData = new FormData();
    formData.append("data", JSON.stringify(newData));
    formData.append("image", image);
    const url = `${config.apiserver}/dashboard/store/storestore/`;
    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const storeFromJson = () => {
  console.log("--------------------------------");

  let address = {
    city: 6840,
    coordinates: [50.45000076293945, 30.523300170898438],
    country: 14,
    district: "",
    fullAddress: "werwerwerwe",
    state: 3906,
    title: "address title",
    town: "wer",
    type: "store",
  };

  let store = {
    address: "",
    description: "23423423",
    images: [],
    international: [],
    owner: "3333",
    products: [],
    shoppingServices: ["schedule", "fast"],
    spatialRanges: [
      { lat: 50.45000076293945, lng: 30.523300170898438 },
      { lat: 50.45020076293945, lng: 30.534500170898436 },
      { lat: 50.46050076293945, lng: 30.523800170898436 },
    ],
    spatialRangesObj: [
      { lat: 50.45000076293945, lng: 30.523300170898438 },
      { lat: 50.45020076293945, lng: 30.534500170898436 },
      { lat: 50.46050076293945, lng: 30.523800170898436 },
    ],
    superStore: false,
    title: "2222333324234",
    topStore: "6361159f3b865e66e7aa62d6",
    visible: true,
  };
};
export const updateExistStore = async (newData, image, token) => {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(newData));
    formData.append("image", image);
    const url = `${config.apiserver}/dashboard/store/updatestore/`;

    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const deleteStore = async (id, token) => {
  try {
    const url = `${config.apiserver}/dashboard/store/store/${id}`;
    console.log(url);
    const result = await Axios.delete(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const uploadImages = async (file, token) => {
  try {
    const formData = new FormData();
    formData.append("image", file);
    const url = `${config.apiserver}/dashboard/store/uploadimages/`;
    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const deleteStoreImage = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/store/deleteStoreImage/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateStoreConfirm = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/store/updatestoreconfirm/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
