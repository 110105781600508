import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Joi from "joi";
import { getCurrencies } from "controllers/CurrencyController";
import { successMessage } from "utiliz/showMessage";
import { errorMessage } from "utiliz/showMessage";
import { dataErrorsMessage } from "utiliz/showMessage";
import { catchMessage } from "utiliz/showMessage";
import { getCountriesList } from "controllers/AddressController";
import { getCitiesList } from "controllers/AddressController";
import { getStatesList } from "controllers/AddressController";
import config from "configs/config";
import { updateUserProfile } from "controllers/UserController";
import { getUserProfile } from "controllers/UserController";
import { storeNewAddress } from "controllers/AddressController";
import { updateExistAddress } from "controllers/AddressController";
import { getStoreAdminProfile } from "controllers/UserController";

let newData = {
  id: 0,
  city: 0,
  country: 0,
  state: 0,
  phone2: 0,
  picture: "",
  currency: "",
  address: "",
};
let newAddressData = {
  id: "",
  country: "",
  state: "",
  city: "",
  title: "",
  type: "storeuser",
  town: "",
  district: "",
  fullAddress: "",
  coordinates: [46.296517308917885, 38.082384634572435],
};
function UpdateProfile() {
  const [controller] = useMaterialUIController();
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [cities, setCities] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(newData);
  const [, setReload] = useState(new Date());
  const [currencies, setCurrencies] = useState();
  const [addressFields, setAddressFields] = useState(newAddressData);
  const navigate = useNavigate();
  const clearForm = () => {
    newData = {
      id: 0,
      city: 0,
      country: 0,
      state: 0,
      phone2: 0,
      picture: "",
      currency: "",
      address: "",
    };
    newAddressData = {
      country: "",
      state: "",
      city: "",
      title: "",
      type: "storeuser",
      town: "",
      district: "",
      fullAddress: "",
      coordinates: [46.296517308917885, 38.082384634572435],
    };
    setSelectedImage();
    setFields(newData);
    setAddressFields(newAddressData);
    setReload(new Date());
  };

  const getUserData = async () => {
    try {
      if (controller.userRole === "storeadmin") {
        console.log("storeeeeeeeee");
        setLoading(true);
        const res = await getStoreAdminProfile(
          { id: controller.id },
          controller.token
        );
        setLoading(false);
        console.log("resss", res.data);
        if (res.success) {
          newData.id = controller.id;
          if (res.data.users) {
            handleCountryChange(res.data.users.country);
            handleStateChange(res.data.users.state);
            newData.phone2 = res.data.users.phone2;
            newData.picture = res.data.users.picture;
            newData.currency = res.data.users.currency;
            newAddressData.country = res.data.users.country;
            newAddressData.state = res.data.users.state;
            newAddressData.city = res.data.users.city;
          }
          if (res.data.addresses) {
            console.log(res.data.addresses);
            newData.address = res.data.addresses._id;
            newAddressData.id = res.data.addresses._id;
            newAddressData.district = res.data.addresses.district;
            newAddressData.town = res.data.addresses.town;
            newAddressData.fullAddress = res.data.addresses.fullAddress;
            newAddressData.title = res.data.addresses.title;
            setAddressFields(newAddressData);
            setReload(new Date());
          }
          setFields(newData);
          setReload(new Date());
        } else {
          errorMessage(res.message, res.code);
        }
        if (res.code === 401) {
          navigate("/", { replace: true });
        }
      } else {
        setLoading(true);
        const res = await getUserProfile(
          { id: controller.id },
          controller.token
        );
        setLoading(false);
        console.log("resss", res.data);
        if (res.success) {
          newData.id = controller.id;
          handleCountryChange(res.data.country);
          handleStateChange(res.data.state);
          newData.phone2 = res.data.phone2;
          newData.picture = res.data.picture;
          newData.currency = res.data.currency;
          newData.country = res.data.country;
          newData.state = res.data.state;
          newData.city = res.data.city;
          setFields(newData);
          setReload(new Date());
        } else {
          errorMessage(res.message, res.code);
        }
        if (res.code === 401) {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const getCountries = async () => {
    try {
      setLoading(true);
      const res = await getCountriesList();
      setLoading(false);
      if (res.success) {
        setCountries(res.data);
      } else {
        errorMessage(res.message, res.code);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleCountryChange = async (country) => {
    try {
      let temp = fields;
      temp.country = country;
      let addressTemp = addressFields;
      addressTemp.country = country;
      setAddressFields(addressTemp);
      setFields(temp);
      setReload(new Date());
      setLoading(true);
      const res = await getStatesList(country);
      setLoading(false);
      if (res.success) {
        setStates(res.data);
      } else {
        errorMessage(res.message, res.code);
      }
      setCities();
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const handleStateChange = async (state) => {
    try {
      const temp = fields;
      temp.state = state;
      let addressTemp = addressFields;
      addressTemp.state = state;
      setAddressFields(addressTemp);
      setFields(temp);
      setReload(new Date());
      setLoading(true);
      const res = await getCitiesList(state);
      setLoading(false);
      if (res.success) {
        setCities(res.data);
      } else {
        errorMessage(res.message, res.code);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };
  const getCurrenciesList = async () => {
    try {
      let dataErrors = "";
      setLoading(true);
      const resCurrency = await getCurrencies();
      setLoading(false);
      if (resCurrency.success) {
        setCurrencies(resCurrency.data);
      } else {
        dataErrors += `${resCurrency.message}<br />`;
      }
      if (dataErrors !== "") {
        dataErrorsMessage(dataErrors);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const changeHandler = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const validateFields = () => {
    try {
      const schema = Joi.object({
        city: Joi.number().required(),
        country: Joi.number().required(),
        state: Joi.number().required(),
        phone2: Joi.number(),
        currency: Joi.string().required(),
      });
      const { error } = schema.validate({
        city: fields.city,
        country: fields.country,
        state: fields.state,
        phone2: fields.phone2,
        currency: fields.currency,
      });
      if (error) {
        errorMessage(error.details[0].message);
        return false;
      }
      return true;
    } catch (error) {
      setLoading(false);
      catchMessage(error);
      return false;
    }
  };

  const handleSubmitStoreAdminClick = async () => {
    let validationResult = await validateFields();
    if (!validationResult) {
      return;
    }
    try {
      const temp = fields;
      temp.id = controller.id;
      setFields(temp);
      setReload(new Date());
      let resAddress;
      if (addressFields.id.length > 0) {
        setLoading(true);
        resAddress = await updateExistAddress(addressFields, controller.token);
        setLoading(false);
      } else {
        setLoading(true);
        resAddress = await storeNewAddress(addressFields, controller.token);
        setLoading(false);
        if (resAddress.success) {
          const temp = fields;
          temp.address = resAddress.data;
          setFields(temp);
          setReload(new Date());
        }
      }
      if (resAddress.success) {
        setLoading(true);
        const res = await updateUserProfile(
          temp,
          selectedImage,
          controller.token
        );
        setLoading(false);
        if (res.success) {
          successMessage(res.message);
          clearForm();
          // navigate(`/`);
        } else {
          errorMessage(res.message, res.code);
        }
        if (res.code === 401) {
          navigate("/", { replace: true });
        }
      } else {
        errorMessage(resAddress.message, resAddress.code);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleSubmitCityAdminClick = async () => {
    let validationResult = await validateFields();
    if (!validationResult) {
      return;
    }
    try {
      const temp = fields;
      temp.id = controller.id;
      setFields(temp);
      setReload(new Date());
      setLoading(true);
      const res = await updateUserProfile(
        temp,
        selectedImage,
        controller.token
      );
      setLoading(false);
      if (res.success) {
        successMessage(res.message);
        clearForm();
        // navigate(`/`);
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  useEffect(() => {
    getUserData();
    getCountries();
    getCurrenciesList();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ flexDirection: "row", margin: 0 }}
                  >
                    <MDTypography variant="h6" color="white">
                      Update Your Profile
                    </MDTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDButton
                      size="small"
                      style={{ float: "Right" }}
                      variant="contained"
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      Back
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox py={3} px={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-multiple-store-label">
                          Currency
                        </InputLabel>
                        <Select
                          labelId="label-currency"
                          label="Currency"
                          id="Currency"
                          value={fields.currency}
                          onChange={(event) => {
                            const temp = fields;
                            temp.currency = event.target.value;
                            setFields(temp);
                            setReload(new Date());
                          }}
                        >
                          {currencies &&
                            currencies.map((currencyItem) => (
                              <MenuItem
                                key={currencyItem.currency}
                                value={currencyItem.currency}
                                title={currencyItem.currency}
                              >
                                {currencyItem.symbol} {currencyItem.currency}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Secound Phone Number"
                        type="number"
                        variant="outlined"
                        value={fields.phone2}
                        onChange={(event) => {
                          const temp = fields;
                          temp.phone2 = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="image"
                          name="image"
                          onChange={changeHandler}
                        />
                        <div
                          id="imageStatus"
                          className="custom-file-label"
                          htmlFor="selectedImage"
                        >
                          {selectedImage && selectedImage.name}
                          {!selectedImage && "Select image"}
                        </div>
                      </div>
                    </Grid>
                    {fields.picture && (
                      <Grid item lg={4} md={6} xs={12}>
                        <img
                          src={`${config.fileserver}/user/${fields.picture}`}
                          alt={"user pic"}
                          height={40}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    {(countries || fields.country !== 0) && (
                      <Grid item lg={4} md={6} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-store-label">
                            Country
                          </InputLabel>
                          <Select
                            labelId="label-country"
                            label="Country"
                            id="Country"
                            value={addressFields.country}
                            onChange={(event) => {
                              handleCountryChange(event.target.value);
                            }}
                          >
                            {countries &&
                              countries.map((countryItem) => (
                                <MenuItem
                                  key={countryItem.id}
                                  value={countryItem.id}
                                  title={countryItem.name}
                                >
                                  {countryItem.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {((states && states.length > 0) || fields.state !== 0) && (
                      <Grid item lg={4} md={6} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-store-label">
                            State
                          </InputLabel>
                          <Select
                            labelId="label-country"
                            label="State"
                            id="State"
                            value={addressFields.state}
                            onChange={(event) => {
                              handleStateChange(event.target.value);
                            }}
                          >
                            {states &&
                              states.map((stateItem) => (
                                <MenuItem
                                  key={stateItem.id}
                                  value={stateItem.id}
                                  title={stateItem.name}
                                >
                                  {stateItem.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {((cities && cities.length > 0) || fields.city !== 0) && (
                      <Grid item lg={4} md={6} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-store-label">
                            City
                          </InputLabel>
                          <Select
                            labelId="label-city"
                            label="City"
                            id="City"
                            value={addressFields.city}
                            onChange={(event) => {
                              const temp = fields;
                              temp.city = event.target.value;
                              let addressTemp = addressFields;
                              addressTemp.city = event.target.value;
                              setAddressFields(addressTemp);
                              setFields(temp);
                              setReload(new Date());
                            }}
                          >
                            {cities &&
                              cities.map((cityItem) => (
                                <MenuItem
                                  key={cityItem.id}
                                  value={cityItem.id}
                                  title={cityItem.name}
                                >
                                  {cityItem.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                  {controller.userRole === "storeadmin" && (
                    <>
                      {" "}
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={4} md={6} xs={12}>
                          <TextField
                            id="outlined-basic_address_title"
                            fullWidth
                            label="Title"
                            variant="outlined"
                            value={addressFields.title}
                            onChange={(event) => {
                              const temp = addressFields;
                              temp.title = event.target.value;
                              setAddressFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                          <TextField
                            id="outlined-basic_address_town"
                            fullWidth
                            label="town"
                            variant="outlined"
                            value={addressFields.town}
                            onChange={(event) => {
                              const temp = addressFields;
                              temp.town = event.target.value;
                              setAddressFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                          <TextField
                            id="outlined-basic_address_district"
                            fullWidth
                            label="district"
                            variant="outlined"
                            value={addressFields.district}
                            onChange={(event) => {
                              const temp = addressFields;
                              temp.district = event.target.value;
                              setAddressFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={1}>
                        <Grid item lg={12} md={12} xs={12}>
                          <TextField
                            id="outlined-basic_address_fullAddress"
                            fullWidth
                            label="fullAddress"
                            variant="outlined"
                            value={addressFields.fullAddress}
                            onChange={(event) => {
                              const temp = addressFields;
                              temp.fullAddress = event.target.value;
                              setAddressFields(temp);
                              setReload(new Date());
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        variant="contained"
                        color="info"
                        onClick={() => {
                          controller.userRole === "storeadmin"
                            ? handleSubmitStoreAdminClick()
                            : handleSubmitCityAdminClick();
                        }}
                      >
                        Update
                      </MDButton>
                      <MDButton
                        size="small"
                        style={{ float: "Right", marginRight: 10 }}
                        variant="contained"
                        onClick={() => {
                          navigate(`/`);
                        }}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                  </Grid>
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UpdateProfile;
