import Axios from "axios";
import config from "../configs/config";

const returnError = (err) => {
  console.log(err);
  const data = {
    success: false,
    message: "There is a problem",
  };
  return data;
};

export const uploadImages = async (file, token) => {
  try {
    const formData = new FormData();
    formData.append("image", file);
    const url = `${config.apiserver}/dashboard/product/uploadimages/`;
    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getProducts = async (rowsPerPage, page, search, store, brand, token) => {
  try {
    const url = `${config.apiserver}/dashboard/product/getproducts/`;
    const result = await Axios.post(
      url,
      { rowsPerPage, page, search, store, brand },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getProduct = async (id, token) => {
  try {
    const url = `${config.apiserver}/dashboard/product/getproduct/${id}`;

    const result = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(url, result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getProductsForStore = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/product/getproductsforstore/`;

    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(url, result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getProductForStore = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/product/getproductforstore/`;

    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(url, result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const storeNewProduct = async (newData, image, token) => {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(newData));
    formData.append("image", image);
    const url = `${config.apiserver}/dashboard/product/storeproduct/`;

    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const updateExistProduct = async (newData, image, token) => {
  try {
    const url = `${config.apiserver}/dashboard/product/updateproduct/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateExistProduct2 = async (newData, image, token) => {
  try {
    const url = `${config.apiserver}/dashboard/product/updateproduct2/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateExistProductAdmin = async (newData, image, token) => {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(newData));
    formData.append("image", image);
    const url = `${config.apiserver}/dashboard/product/updateproductadmin/`;
    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateExistProduct1 = async (newData, image, token) => {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(newData));
    formData.append("image", image);
    const url = `${config.apiserver}/dashboard/product/updateproduct/`;

    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const deleteProduct = async (id, token) => {
  try {
    const url = `${config.apiserver}/dashboard/product/product/${id}`;
    const result = await Axios.delete(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getInternationals = async (token) => {
  try {
    const url = `${config.apiserver}/general/getinternationals`;
    const result = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(url, result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

