import Axios from "axios";
import config from "../configs/config";

const returnError = (err) => {
  console.log(err);
  const data = {
    success: false,
    message: "There is a problem",
  };
  return data;
};

export const getTopCategories = async (token) => {
  try {
    const url = `${config.apiserver}/dashboard/category/gettopcategories/`;
    const result = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getSubCategories = async (token) => {
  try {
    const url = `${config.apiserver}/dashboard/product/getsubcategories/`;
    const result = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(url, result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getCategories = async (rowsPerPage, page, search, token) => {
  try {
    const url = `${config.apiserver}/dashboard/category/getcategories/`;
    const result = await Axios.post(
      url,
      { rowsPerPage, page, search },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getCategory = async (id, token) => {
  try {
    const url = `${config.apiserver}/dashboard/category/getcategory/${id}`;
    const result = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const storeNewCategory = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/category/storecategory/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const updateExistCategory = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/category/updatecategory/`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const deletecategory = async (id, token) => {
  try {
    const url = `${config.apiserver}/dashboard/category/category/${id}`;
    console.log(url);
    const result = await Axios.delete(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateCategoryReaded = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/category/updateCategoryReaded`;
    const result = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
