import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LoopIcon from "@mui/icons-material/Loop";
import PreviewIcon from "@mui/icons-material/Preview";
import CloseIcon from "@mui/icons-material/Close";

// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { styled } from "@mui/material/styles";
// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { Link, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import TablePaginationActions from "examples/Tables/TablePaginationActions";
import { catchMessage } from "utiliz/showMessage";
import { errorMessage } from "utiliz/showMessage";

import { getProductsWithoutBrands } from "controllers/ProductWithoutBrandController";
import { getProductWithoutBrands } from "controllers/ProductWithoutBrandController";
import { getColesNewProducts } from "controllers/ProductWithoutBrandController";
import ViewColesProduct from "./viewColesProduct";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Index() {
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [datas, setDatas] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [coleId, setColeId] = useState("");
  const [index, setIndex] = useState(0);
  const [reload, setReload] = useState(new Date());

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  let emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  // Avoid a layout jump when reaching the last page with empty rows.
  const getData = async (rowsPerPageParam, pageParam, searchParam) => {
    try {
      setLoading(true);
      setDatas([]);
      const reqDatas = {
        rowsPerPageParam,
        pageParam,
        searchParam,
      };
      const res = await getColesNewProducts(reqDatas, controller.token);
      setLoading(false);
      if (res.success) {
        setDatas(res.data);
        setCount(res.count);
        emptyRows =
          rowsPerPage -
          Math.min(rowsPerPage, datas.length - (pageParam - 1) * rowsPerPage);
      } else {
        errorMessage(res.message, res.code);
        if (res.code === 401) {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleSetCoId = (number) => {
    if (!datas[index + 1]) {
      errorMessage("please go to next page");
      return datas[index].id;
    } else {
      if (number === 1) {
        setIndex(index + 1);
        setColeId(datas[index + 1].id);
        return datas[index + 1].id;
      } else {
        return datas[index+1].id;
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(rowsPerPage, newPage + 1, search);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getData(parseInt(event.target.value, 10), 1, search);
  };

  const handleEditClick = async (id) => {
    try {
      setLoading(true);
      const res = await getProductWithoutBrands({ id }, controller.token);
      setLoading(false);
      if (res.success) {
        if (res.isEdit) {
          console.log(res.data);
          navigate(`/updateProductWithoutBrand/${id}/${res.data.product}`);
        } else {
          console.log(res);
          navigate(`/updateProductWithoutBrand/${id}`);
        }
      } else {
        errorMessage(res.message);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  useEffect(() => {
    getData(10, 1, "");
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ flexDirection: "row", margin: 0 }}
                  >
                    <TextField
                      autoFocus
                      id="search"
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={search}
                      placeholder="search ..."
                      onChange={(event) => {
                        setSearch(event.target.value);
                        setPage(0);
                        getData(rowsPerPage, page + 1, event.target.value);
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          setPage(0);
                          getData(rowsPerPage, page + 1, ev.target.value);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500 }}>
                      <TableBody>
                        <TableRow style={{ backgroundColor: "#ccc" }}>
                          <TableCell style={{ width: 40, textAlign: "center" }}>
                            row
                          </TableCell>
                          <TableCell>title</TableCell>
                          <TableCell>name</TableCell>
                          <TableCell
                            style={{ maxWidth: 90, textAlign: "center" }}
                          >
                            similar products
                          </TableCell>
                          <TableCell
                            style={{ maxWidth: 90, textAlign: "center" }}
                          >
                            Image
                          </TableCell>
                          <TableCell
                            style={{ width: 150, textAlign: "center" }}
                          >
                            menu
                          </TableCell>
                        </TableRow>
                        {datas.map((row, ind) => (
                          <StyledTableRow
                            onClick={() => {
                              setColeId(datas[ind].id);
                              setIndex(ind);
                              handleOpen();
                            }}
                            key={row.id}
                          >
                            <TableCell>
                              {rowsPerPage * page + ind + 1}
                            </TableCell>

                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.length}</TableCell>
                            <TableCell>
                              <img src={row.image} height={70} Alt={""} />
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                title="View"
                                aria-label="view"
                                style={{
                                  cursor: "pointer",
                                  width: 20,
                                  height: 20,
                                }}
                              >
                                <LoopIcon
                                  color="secondary"
                                  onClick={() => {
                                    setColeId(row.id);
                                    handleOpen();
                                  }}
                                />
                              </Tooltip>

                              <Tooltip title="preview" aria-label="preview">
                                <Link to={row.link} target="_blank">
                                  {" "}
                                  <PreviewIcon
                                    style={{
                                      cursor: "pointer",
                                      width: 20,
                                      height: 20,
                                    }}
                                    color="secondary"
                                  />
                                </Link>
                              </Tooltip>
                            </TableCell>
                          </StyledTableRow>
                        ))}

                        {emptyRows > 0 && (
                          <TableRow style={{ height: 10 * emptyRows }}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "All", value: count },
                            ]}
                            colSpan={8}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                "aria-label": "Rows per page",
                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog fullWidth maxWidth={true} open={open} onClose={handleClose}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} xs={3}>
            <IconButton
              style={{ margin: "2px" }}
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <ViewColesProduct
            id={coleId}
            closeModal={handleClose}
            getData={getData}
            rowsPerPage={rowsPerPage}
            page={page}
            search={search}
            index={index}
            handleSetCoId={handleSetCoId}
          />
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default Index;
