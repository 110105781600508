//
/* eslint-disable no-plusplus */
import { useCallback, useEffect, useRef, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Acromegaly React components
import MDBox from "components/MDBox";
import Swal from "sweetalert2";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  Box,
  Chip,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Joi from "joi";
import { LoadScript, GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import {
  storeNewAddress,
  getCountriesList,
  getStatesList,
  getCitiesList,
} from "controllers/AddressController";
import {
  storeNewStore,
  getSupeStoresList,
  uploadImages,
} from "controllers/StoreController";
import { getIpInfo } from "controllers/Controller";
import { getInternationals } from "controllers/ProductController";
import { FileUploader } from "react-drag-drop-files";
import config from "configs/config";
import { getPlaces } from "controllers/AddressController";
import { storeFromJson } from "controllers/StoreController";

import woolworth from "../../controllers/woolworth.json";
import { getTopCategories } from "controllers/CategoryController";
import { getCategories } from "controllers/CategoryController";
import { getSubCategories } from "controllers/CategoryController";
import { startScrape, scrapeWoolworthBrands } from "controllers/Controller";
import { startScrapeColes } from "controllers/Controller";
import { startScrapePageDivision } from "controllers/Controller";
import { startScrapeAldi } from "controllers/Controller";
import { startScrapeColesPageCount } from "controllers/Controller";
import { updateCategoryReaded } from "controllers/CategoryController";
import { successMessage } from "utiliz/showMessage";

let newData = {
  categories: [],
};
const shoppingServices = [
  { id: "fast", text: "Fast Sending" },
  { id: "schedule", text: "Schedule Sending" },
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function StartScrape() {
  const [controller] = useMaterialUIController();
  const [categories, setCategories] = useState([]);
  const [fields, setFields] = useState(newData);
  const [woolworthLink, setWoolworthLink] = useState("");
  const [colesLink, setColesLink] = useState("");
  const [aldiLink, setAldiLink] = useState("");
  const [, setReload] = useState(new Date());
  const [woolPages, setWoolPages] = useState();
  const [colesPages, setColesPages] = useState();
  const [aldiPages, setAldiPages] = useState();

  const [loading, setLoading] = useState(false);

  const getCategoriesFunc = async () => {
    const res = await getSubCategories(controller.token);
    console.log(res);
    if (res.success) {
      setCategories(res.data);
    } else {
      // dataErrors += `${res.message}<br />`;
    }
  };
  const handleStartScrape = async (pages, url) => {
    fields.pages = pages;
    fields.url = url;
    setLoading(true);
    const res = await startScrape(fields, controller.token);
    setLoading(false);
    if (res.success) {
      // setCategories(res.data);
    } else {
      Swal.fire({
        text: `${res.message} (${res.code})`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const handleStartScrapeDivision = async (category) => {
    setLoading(true);
    const res = await startScrapePageDivision(fields, controller.token);
    setLoading(false);
    if (res.success) {
      console.log("res.data.coles", res.data);
      setWoolPages(res.data.woolworths);
      setColesPages(res.data.coles);
      setAldiPages(res.data.aldi);
    } else {
      Swal.fire({
        text: `${res.message} (${res.code})`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  //
  const handleStartScrapeColes = async (pages, url) => {
    fields.pages = pages;
    fields.url = url;
    const res = await startScrapeColes(fields, controller.token);

    if (res.success) {
      // setCategories(res.data);
    } else {
      Swal.fire({
        text: `${res.message} (${res.code})`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const handleStartScrapeColesPages = async (pages, url) => {
    fields.pages = pages;
    fields.url = url;
    const res = await startScrapeColes(fields, controller.token);

    if (res.success) {
      // setCategories(res.data);
    } else {
      Swal.fire({
        text: `${res.message} (${res.code})`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const handleStartScrapeAldi = async (pages, url) => {
    fields.pages = pages;
    fields.url = url;
    const res = await startScrapeAldi(fields, controller.token);

    if (res.success) {
      // setCategories(res.data);
    } else {
      Swal.fire({
        text: `${res.message} (${res.code})`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const handleClearWoolworth = async () => {
    const res = await updateCategoryReaded(
      { page: "woolworth" },
      controller.token
    );
    if (res.success) {
      successMessage(res.message);
    } else {
      Swal.fire({
        text: `${res.message} (${res.code})`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const handleClearColes = async () => {
    const res = await updateCategoryReaded({ page: "coles" }, controller.token);
    if (res.success) {
      successMessage(res.message);
    } else {
      Swal.fire({
        text: `${res.message} (${res.code})`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const handleClearAldi = async () => {
    const res = await updateCategoryReaded({ page: "aldi" }, controller.token);
    if (res.success) {
      successMessage(res.message);
    } else {
      Swal.fire({
        text: `${res.message} (${res.code})`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    // topCategoriesFunc();
    getCategoriesFunc();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ flexDirection: "row", margin: 0 }}
                  >
                    <MDTypography variant="h6" color="white"></MDTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
              </MDBox>
              <MDBox py={3} px={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  {/* <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">
                          Categories
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={fields.categories}
                          onChange={handleCategoryChange}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Categories"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={
                                    categories.filter(
                                      (a) => `${a.id}''` === `${value}''`
                                    )[0].title
                                  }
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {categories &&
                            categories.map((categoryItem) => (
                              <MenuItem
                                key={categoryItem.id}
                                value={categoryItem.id}
                                title={categoryItem.title}
                                style={{
                                  paddingLeft: categoryItem.padding,
                                  color: categoryItem.color,
                                }}
                              >
                                {categoryItem.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid> */}
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="simple-select-label-topcategory">
                          Category
                        </InputLabel>
                        <Select
                          id="simple-select-topcategory"
                          labelId="simple-select-label-topcategory"
                          label="Top Category"
                          value={fields.category}
                          onChange={(event) => {
                            const temp = fields;
                            temp.category = event.target.value;
                            setFields(temp);
                            setReload(new Date());
                            let w = categories.filter(
                              (a) => a.id + "" == event.target.value
                            );
                            console.log("-----", w);
                            if (w.length > 0) {
                              setWoolworthLink(w[0].woolworth);
                              setColesLink(w[0].coles);
                              setAldiLink(w[0].aldi);
                              handleStartScrapeDivision(event.target.value);
                              setReload(new Date());
                            }
                          }}
                        >
                          {categories?.map((item) => (
                            <MenuItem value={item.id}>{item.title}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      {woolworthLink}
                      <hr />
                      {colesLink}
                      <hr />
                      {aldiLink}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        variant="contained"
                        color="info"
                        onClick={() => {
                          //
                          scrapeWoolworthBrands();
                          // handleStartScrapeDivision(fields.category);
                        }}
                      >
                        scrape Woolworth Brands
                      </MDButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mt={1} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        color="info"
                        onClick={() => {
                          handleStartScrape();
                        }}
                      >
                        Woolworths
                      </MDButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mt={1} mb={1}>
                    <Grid item lg={6} md={6} xs={12}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        color="info"
                        onClick={() => {
                          handleStartScrapeColes();
                        }}
                      >
                        Coles
                      </MDButton>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        color="info"
                        onClick={() => {
                          startScrapeColesPageCount();
                        }}
                      >
                        Coles pages
                      </MDButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mt={1} mb={1}>
                    {woolPages?.map((woolworths) => (
                      <Grid item lg={12} md={12} xs={12}>
                        {woolworths.pages?.map((item) => (
                          <MDButton
                            size="small"
                            style={{ float: "Right" }}
                            color="info"
                            onClick={() => {
                              handleStartScrape(item, woolworths.url);
                            }}
                          >
                            {`  ${item[0]} - ${item[1]}`}
                          </MDButton>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={2} mt={2} mb={2}>
                    {colesPages?.map((colse) => (
                      <Grid item lg={12} md={12} xs={12}>
                        {colse.pages?.map((item) => (
                          <MDButton
                            size="small"
                            color="warning"
                            onClick={() => {
                              handleStartScrapeColes(item, colse.url);
                            }}
                          >
                            {` ${item[0]} - ${item[1]}`}
                          </MDButton>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={2} mt={2} mb={2}>
                    {aldiPages?.map((aldi) => (
                      <Grid item lg={12} md={12} xs={12}>
                        {aldi.pages?.map((item) => (
                          <MDButton
                            size="small"
                            color="warning"
                            onClick={() => {
                              handleStartScrapeAldi(item, aldi.url);
                            }}
                          >
                            {` ${item[0]} - ${item[1]}`}
                          </MDButton>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item lg={3} md={3} xs={3}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        color="info"
                        onClick={() => {
                          handleClearWoolworth();
                        }}
                      >
                        Clear Readed Woolworth
                      </MDButton>
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        color="info"
                        onClick={() => {
                          handleClearColes();
                        }}
                      >
                        Clear Readed Coles
                      </MDButton>
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        color="info"
                        onClick={() => {
                          handleClearAldi();
                        }}
                      >
                        Clear Readed Aldi
                      </MDButton>
                    </Grid>
                  </Grid>
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default StartScrape;
