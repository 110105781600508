import { useState } from "react";
// react-router-dom components
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import LoadingOverlay from "react-loading-overlay";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import Joi from "joi";
// Market Place React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "views/authentication/components/BasicLayout";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { login } from "controllers/UserController";
import { useMaterialUIController, setLogin } from "context";
import { errorMessage } from "utiliz/showMessage";
import { catchMessage } from "utiliz/showMessage";
import { getStoreAdminProfile } from "controllers/UserController";

function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useMaterialUIController();

  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const getStoreAdminData = async (id, token) => {
    try {
      setLoading(true);
      const resStoreAdmin = await getStoreAdminProfile({ id: id }, token);
      setLoading(false);
      if (resStoreAdmin.success) {
        console.log(resStoreAdmin.data);
        if (
          resStoreAdmin.data.isActiveEmail &&
          resStoreAdmin.data.isActivePhone
        ) {
          //Location.href("/stores");
          navigate("/stores", { replace: true });
        } else if (
          !resStoreAdmin.data.isActiveEmail ||
          !resStoreAdmin.data.isActivePhone
        ) {
          navigate(`/UserActivation/${id}`, { replace: true });
        }
      } else {
        errorMessage(resStoreAdmin.message, resStoreAdmin.code);
      }
      if (resStoreAdmin.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleSubmitClick = async () => {
    const schema = Joi.object({
      username: Joi.string().allow("").optional(),
      password: Joi.string().required().min(3).max(256),
    });

    const { error } = schema.validate({
      username,
      password,
    });

    if (error) {
      errorMessage(error.details[0].message);
      return;
    }
    setLoading(true);
    const newUser = {
      username,
      password,
    };
    const res = await login(newUser);
    setLoading(false);
    setLogin(dispatch, res.data);
    if (res.success) {
      
      console.log("res.data", res.data);
        navigate("/startscrape", { replace: true });
      // clearForm();
    } else {
      errorMessage(res.message, res.code);
    }
    if (res.code === 401) {
      navigate("/", { replace: true });
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <LoadingOverlay active={loading} spinner text="Please wait ...">
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="username"
                  label="Username"
                  variant="standard"
                  fullWidth
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => {
                    handleSubmitClick();
                  }}
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MDButton
                    onClick={() => {
                      navigate("/authentication/store-sign-up");
                    }}
                  >
                    Sign up
                  </MDButton>
                </MDTypography>
              </MDBox>
            </MDBox>
          </LoadingOverlay>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
