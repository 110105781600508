/**
=========================================================
* Market Place React - v2.1.0
=========================================================

* Product Page: https://litech.site/product/material-dashboard-react
* Copyright 2022 Li-tech (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Market Place React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Market Place React context
import { useMaterialUIController } from "context";

function SidenavCollapse({ icon, name, active, ...rest }) {
  const [controller] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;

  return (
    <ListItem component="li">
      <MDBox
        {...rest}
        //</ListItem>sx={(theme) =>
        // collapseItem(theme, {
        //   active: active ? active : false,
        //   transparentSidenav: transparentSidenav ? transparentSidenav : false,
        //   whiteSidenav: whiteSidenav ? whiteSidenav : false,
        //   darkMode: darkMode ? darkMode : false,
        //   sidenavColor: sidenavColor ? sidenavColor : false,
        // })
        // }
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: 8,
          marginHorizontal: 16,

          cursor: "pointer",
          userSelect: "none",
          whiteSpace: "nowrap",
        }}
      >
        <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, {
              active: active ? active : false,
              transparentSidenav: transparentSidenav
                ? transparentSidenav
                : false,
              whiteSidenav: whiteSidenav ? whiteSidenav : false,
              darkMode: darkMode ? darkMode : false,
            })
          }
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          style={{ color: "#FFF" }}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav: miniSidenav ? miniSidenav : false,
              active: active ? active : false,
              transparentSidenav: transparentSidenav
                ? transparentSidenav
                : false,
              whiteSidenav: whiteSidenav ? whiteSidenav : false,
            })
          }
        />
      </MDBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
