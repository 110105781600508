import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BallotIcon from "@mui/icons-material/Ballot";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import TwoWheelerOutlinedIcon from "@mui/icons-material/TwoWheelerOutlined";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import PublicIcon from "@mui/icons-material/Public";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";

// Market Place React views
import Tables from "views/tables";
import Brand from "views/brand";
import StoreBrand from "views/brand/storeBrand";
import UpdateBrand from "views/brand/updateBrand";
import Product from "views/product";
import StoreProduct from "views/product/storeProduct";
import UpdateProduct from "views/product/updateProduct";
import AdminUpdateProduct from "views/product/adminUpdateProduct";
import ProductWithoutBrand from "views/productWithoutBrand";

import Store from "views/store";
import StoreStore from "views/store/storeStore";
import UpdateStore from "views/store/updateStore";

import Country from "views/applicationBase/country";
import UpdateCountry from "views/applicationBase/country/updateCountry";
import State from "views/applicationBase/state";
import StoreState from "views/applicationBase/state/storeState";
import UpdateState from "views/applicationBase/state/updateState";
import City from "views/applicationBase/city";
import StoreCity from "views/applicationBase/city/storeCity";
import UpdateCity from "views/applicationBase/city/updateCity";
import Slider from "views/applicationBase/slider";
import Schedule from "views/schedule";
import StoreSchedule from "views/schedule/storeSchedule";
import TaxFee from "views/tax_fee";
import StoreTaxFee from "views/tax_fee/storeTaxFee";
import UpdateTaxFee from "views/tax_fee/updateTaxFee";
import Category from "views/category";
import StoreCategory from "views/category/storeCategory";
import UpdateCategory from "views/category/updateCategory";
import Riders from "views/usres/riders";
import Orders from "views/orders";
import Customers from "views/usres/custmers";
import Managers from "views/usres/managers";
import Billing from "views/billing";
import Notifications from "views/notifications";
import Profile from "views/authentication/profile";
import UpdateProfile from "views/authentication/profile/updateProfile";
import SignIn from "views/authentication/sign-in";
import AdminStoreSignUp from "views/authentication/sign-up/adminStoreSignUp";
import SignUp from "views/authentication/sign-up";
import StoreDiscount from "views/discount/storeDiscount";
import UpdateDiscount from "views/discount/updateDiscount";
import Discount from "views/discount/";
import StartScrape from "views/scrape/StartScrape";
// @mui icons
import Icon from "@mui/material/Icon";
import UserActivation from "views/authentication/profile/userActivation";
import UpdateProductWithoutBrand from "views/productWithoutBrand/updateProductWithoutBrand";
import ColesNewProducts from "views/colesNewProducts";
import NewSavedVariants from "views/newSavedVariants";
const routes = [
  {
    type: "collapse",
    name: "StartScrape",
    sidebar: true,
    key: "StartScrape",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/startscrape",
    component: <StartScrape />,
  },
  {
    type: "collapse",
    name: "Update Product Without Brand",
    sidebar: false,
    key: "UpdateProductWithoutBrand",
    icon: <RequestQuoteOutlinedIcon />,
    route: "/updateProductWithoutBrand/:dId/",
    roles: ["cityadmin", "superadmin"],
    component: <UpdateProductWithoutBrand />,
  },
  {
    type: "collapse",
    name: "Update Product Without Brand",
    sidebar: false,
    key: "UpdateProductWithoutBrand",
    icon: <RequestQuoteOutlinedIcon />,
    route: "/updateProductWithoutBrand/:dId/:id",
    roles: ["cityadmin", "superadmin"],
    component: <UpdateProductWithoutBrand />,
  },
  {
    type: "collapse",
    sidebar: true,
    name: "New Producs",
    key: "UserActivation",
    icon: <ScheduleSendIcon />,
    route: "/addnotsaved",
    component: <ColesNewProducts />,
  },
  {
    type: "collapse",
    sidebar: true,
    name: "Producs without brand",
    key: "withoutbrand",
    icon: <ScheduleSendIcon />,
    route: "/newproducts",
    component: <ProductWithoutBrand />,
  },
  {
    type: "collapse",
    sidebar: true,
    name: "Store Product",
    key: "storeproduct",
    icon: <ScheduleSendIcon />,
    route: "storeproduct/:id",
    component: <StoreProduct />,
  },
  {
    type: "collapse",
    sidebar: false,
    name: "Sign In",
    key: "sign-in",
    icon: <ScheduleSendIcon />,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    sidebar: false,
    name: "Store Sign up",
    key: "sign-up-store",
    icon: <ScheduleSendIcon />,
    route: "/authentication/store-sign-up",
    component: <AdminStoreSignUp />,
  },
  {
    type: "collapse",
    sidebar: false,
    name: "Sign Up",
    key: "sign-up",
    icon: <ScheduleSendIcon />,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    sidebar: true,
    name: "New Saved Variants",
    key: "newsavedvariants",
    icon: <ScheduleSendIcon />,
    route: "newsavedvariants",
    component: <NewSavedVariants />,
  },
];
export default routes;
