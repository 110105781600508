import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Joi from "joi";
import { storeNewDiscount } from "controllers/DiscountController";
import { successMessage } from "utiliz/showMessage";
import { errorMessage } from "utiliz/showMessage";

let newData = {
  title: "",
  description: "",
  discount: "",
  maxPrice: "",
  currency: "",
  city: "",
  start: new Date(),
  end: new Date(),
  users: [],
};
function StoreDiscount() {
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(newData);
  const [, setReload] = useState(new Date());
  const navigate = useNavigate();
  const clearForm = () => {
    newData = {
      title: "",
      description: "",
      visible: true,
      products: [],
    };
    setFields(newData);
    setReload(new Date());
  };
  const handleSubmitClick = async () => {
    let temp = {
      title: "welcome",
      discount: 10,
      maxPrice: 5,
      currency: "USD",
      city: 6840,
      start: new Date(),
      end: new Date(),
      users: [],
      description: "dis",
    };
    const schema = Joi.object({
      title: Joi.string().required().min(3).max(256),
    });

    const { error } = schema.validate({
      title: temp.title,
    });
    if (error) {
      errorMessage(error.details[0].message);
      return;
    }
    console.log(temp);
    setLoading(true);
    const res = await storeNewDiscount(temp, controller.token);
    setLoading(false);
    if (res.success) {
      successMessage(res.message);
      clearForm();
    } else {
      errorMessage(res.message,res.code);
    }
    if (res.code === 401) {
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {

  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ flexDirection: "row", margin: 0 }}
                  >
                    <MDTypography variant="h6" color="white">
                      New brand
                    </MDTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDButton
                      size="small"
                      style={{ float: "Right" }}
                      variant="contained"
                      onClick={() => {
                        navigate("/brand");
                      }}
                    >
                      Back
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox py={3} px={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Title"
                        variant="outlined"
                        value={fields.title}
                        onChange={(event) => {
                          const temp = fields;
                          temp.title = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={fields.description}
                        multiline
                        minRows={5}
                        onChange={(event) => {
                          const temp = fields;
                          temp.description = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        variant="contained"
                        color="info"
                        onClick={() => {
                          handleSubmitClick();
                        }}
                      >
                        Create new
                      </MDButton>
                    </Grid>
                  </Grid>
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default StoreDiscount;
