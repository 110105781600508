import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Acromegaly React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "Li-tech";

// Acromegaly React reducer
function reducer(state, action) {
  try {
    switch (action.type) {
      case "MINI_SIDENAV": {
        return { ...state, miniSidenav: action.value };
      }
      case "TRANSPARENT_SIDENAV": {
        return { ...state, transparentSidenav: action.value };
      }
      case "WHITE_SIDENAV": {
        return { ...state, whiteSidenav: action.value };
      }
      case "SIDENAV_COLOR": {
        return { ...state, sidenavColor: action.value };
      }
      case "TRANSPARENT_NAVBAR": {
        return { ...state, transparentNavbar: action.value };
      }
      case "FIXED_NAVBAR": {
        return { ...state, fixedNavbar: action.value };
      }
      case "OPEN_CONFIGURATOR": {
        return { ...state, openConfigurator: action.value };
      }
      case "DIRECTION": {
        return { ...state, direction: action.value };
      }
      case "LAYOUT": {
        return { ...state, layout: action.value };
      }
      case "DARKMODE": {
        return { ...state, darkMode: action.value };
      }
      case "login": {
        console.log("action.value", action.value);
        const newData = {
          ...state,
          token: action.value.token,
          authenticated: true,
          name: action.value.name,
          city: action.value.city,
          currency: action.value.currency,
          userRole: action.value.userRole,
          id:action.value.id
        };
        localStorage.setItem("@user", JSON.stringify(newData));
        return newData;
      }
      case "logout": {
        localStorage.setItem(
          "@user",
          JSON.stringify({
            miniSidenav: true,
            transparentSidenav: false,
            whiteSidenav: false,
            sidenavColor: "success",
            transparentNavbar: true,
            fixedNavbar: true,
            openConfigurator: false,
            direction: "ltr",
            darkMode: false,
            token: "",
            name: "",
            authenticated: false,
            userRole: "",
            id:""
          })
        );
        const newData = {
          miniSidenav: true,
          transparentSidenav: false,
          whiteSidenav: false,
          sidenavColor: "success",
          transparentNavbar: true,
          fixedNavbar: true,
          openConfigurator: false,
          direction: "ltr",
          layout: "dashboard",
          darkMode: false,
          token: "",
          authenticated: false,
          userRole: "",
          id:""
        };

        return newData;
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  } catch (err) {
    console.log(err);
    return state;
  }
}
let initialState = localStorage.getItem("@user");

if (!initialState) {
  initialState = {
    miniSidenav: true,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "success",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "page",
    darkMode: false,
    token: "",
    name: "",
    authenticated: false,
    userRole: "",
    id:""
  };
  localStorage.setItem("@user", JSON.stringify(initialState));
} else {
  initialState = JSON.parse(initialState);
}

// Acromegaly React context provider
function MaterialUIControllerProvider({ children }) {
  // const applicationData = await localStorage.getItem("@user");
  // console.log("applicationData", applicationData);

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Acromegaly React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "use Controller should be used inside the  ControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the  ControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) =>
  dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) =>
  dispatch({ type: "DIRECTION", value });
const setLogin = (dispatch, value) => dispatch({ type: "login", value });
const setLogout = (dispatch, value) => dispatch({ type: "logout", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setLogin,
  setLogout
};
