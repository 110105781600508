import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
} from "@mui/material";
import { getBrand, updateExistBrand } from "controllers/BrandController";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import config from "configs/config";
import Joi from "joi";
import { catchMessage } from "utiliz/showMessage";
import { errorMessage } from "utiliz/showMessage";
import { successMessage } from "utiliz/showMessage";

let newData = {
  id: "",
  title: "",
  description: "",
  visible: true,
  products: [],
};

function UpdateBrand() {
  const { id } = useParams();
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(newData);
  const [, setReload] = useState(new Date());
  const [selectedImage, setSelectedImage] = useState();

  const navigate = useNavigate();
  const clearForm = () => {
    newData = {
      title: "",
      visible: true,
      description: "",
      products: [],
    };
    setFields(newData);
    setSelectedImage();
    setReload(new Date());
  };
  const changeHandler = (event) => {
    console.log("changeHandler", event.target.files[0]);
    setSelectedImage(event.target.files[0]);
  };
  
  const getBrandData = async () => {
    try {
      setLoading(true);
      const res = await getBrand(id, controller.token);
      setLoading(false);
      if (res.success) {
        newData.id = id;
        newData.title = res.data.title;
        newData.description = res.data.description;
        newData.image = res.data.image;
        setFields(newData);
        setReload(new Date());
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleSubmitClick = async () => {
    try {
      const schema = Joi.object({
        title: Joi.string().required().min(3).max(256),
        id: Joi.string().required().min(24).max(24),
      });
      const { error } = schema.validate({
        title: fields.title,
        id: fields.id,
      });
      if (error) {
        errorMessage(error.details[0].message);
        return;
      }
      setLoading(true);
      const res = await updateExistBrand(
        fields,
        selectedImage,
        controller.token
      );
      setLoading(false);
      if (res.success) {
        successMessage(res.message);
        clearForm();
        navigate(`/brand`);
      } else {
        errorMessage(res.message, res.code);
      }
      if (res.code === 401) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  useEffect(() => {
    getBrandData();
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={0.5}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ flexDirection: "row", margin: 0 }}
                  >
                    <MDTypography variant="h6" color="white">
                      Update brand
                    </MDTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDButton
                      size="small"
                      style={{ float: "Right" }}
                      variant="contained"
                      onClick={() => {
                        navigate("/brand");
                      }}
                    >
                      Back
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox py={3} px={3}>
                <LoadingOverlay active={loading} spinner text="Please wait ...">
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Title"
                        variant="outlined"
                        value={fields.title}
                        onChange={(event) => {
                          const temp = fields;
                          temp.title = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="image"
                          name="image"
                          onChange={changeHandler}
                        />
                        <div
                          id="imageStatus"
                          className="custom-file-label"
                          htmlFor="selectedImage"
                        >
                          {selectedImage && selectedImage.name}
                          {!selectedImage && "Select image"}
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={2} md={2} xs={12}>
                      <img
                        src={`${config.apiserver}/uploads/brand/${newData.image}`}
                        height={40}
                        Alt={newData.title}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={fields.description}
                        multiline
                        minRows={5}
                        onChange={(event) => {
                          const temp = fields;
                          temp.description = event.target.value;
                          setFields(temp);
                          setReload(new Date());
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <MDButton
                        size="small"
                        style={{ float: "Right" }}
                        variant="contained"
                        color="info"
                        onClick={() => {
                          handleSubmitClick();
                        }}
                      >
                        Update
                      </MDButton>
                      <MDButton
                        size="small"
                        style={{ float: "Right", marginRight: 10 }}
                        variant="contained"
                        onClick={() => {
                          navigate(`/brand`);
                        }}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                  </Grid>
                </LoadingOverlay>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UpdateBrand;
