import Axios from "axios";
import config from "configs/config";

const returnError = (err) => {
  console.log(err);
  const data = {
    success: false,
    code: 5001,
    message: "There is a problem",
  };
  return data;
};
export const login = async (authData) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/login/`;
    console.log(url);
    const user = await Axios.post(url, authData);
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};
export const register = async (newUser) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/register/`;
    const user = await Axios.post(url, newUser);
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const registerStoreAdmin = async (newUser) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/registerstoreadmin/`;
    const user = await Axios.post(url, newUser);
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getUserProfile = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/getuserprofile/`;
    const user = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getStoreAdminProfile = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/getStoreAdminProfile/`;
    const user = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const sendMobileVerifyCode = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/sendmobileverifycode/`;
    const user = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const sendEmailVerifyCode = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/sendemailverifycode/`;
    const user = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const verifyPhoneNumber = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/verifyPhoneNumber/`;
    const user = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const verifyEmailAddress = async (newData, token) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/verifyEmailAddress/`;
    const user = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateUserProfile = async (newData, image, token) => {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(newData));
    formData.append("image", image);
    const url = `${config.apiserver}/dashboard/auth/updateprofile/`;
    const result = await Axios.post(url, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getUser = async (id, token) => {
  try {
    const url = `${config.apiserver}/dashboard/auth/getuser/${id}`;
    console.log(url);
    const user = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const activation = async (newData, token) => {
  try {
    const url = `${config.apiserver}/admin/updateuseractivation/`;

    const res = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });

    return res.data;
  } catch (err) {
    return returnError(err);
  }
};

export const deleteUser = async (id, token) => {
  try {
    const url = `${config.apiserver}/admin/deleteuser/${id}`;
    const responce = await Axios.delete(url, {
      headers: {
        "x-access-token": token,
      },
    });

    return responce.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updatePermission = async (body, token) => {
  try {
    const url = `${config.apiserver}/updatepermission`;
    const user = await Axios.post(url, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const completProfile = async (profile, picture, token) => {
  try {
    const formData = new FormData();

    // formData.append("picture", picture);
    // formData.append("name", profile.name);
    // formData.append("code", profile.code);
    // formData.append("age", profile.age);
    // formData.append("gender", profile.gender);
    // formData.append("speciality", profile.speciality);
    // formData.append("city", profile.city);
    // formData.append("howToInform", profile.howToInform);
    // formData.append("nationality", profile.nationality);
    // formData.append("institution", profile.institution);
    const url = `${config.apiserver}/app/auth/completedashboradprofile/`;
    console.log(url);
    const data = await Axios.post(url, profile, {
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-access-token": token,
      },
    });

    console.log(data.data);
    return data.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getSpecialities = async () => {
  try {
    const url = `${config.apiserver}/specialities`;
    const specialities = await Axios.get(url);
    return specialities.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getCountries = async () => {
  try {
    const url = `${config.apiserver}/countries`;
    const countries = await Axios.get(url);
    return countries.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getProfile = async (token) => {
  try {
    const url = `${config.apiserver}/getprofile`;
    const user = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });

    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const changeUserRole = async (newDate, token) => {
  try {
    const url = `${config.apiserver}/admin/upateuserrole/`;
    const user = await Axios.put(url, newDate, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const changeUserActiveMode = async (lang, id, mode, token) => {
  try {
    const url = `${config.apiserver}/admin/changeuseractivemode/${lang}/${id}/${mode}`;
    const user = await Axios.put(url, null, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getPassword = async (lang, id, token) => {
  try {
    const url = `${config.apiserver}/admin/getpassword/${lang}/${id}`;
    const user = await Axios.put(url, null, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};

export const getUsersList = async (perpage, page, search, token) => {
  try {
    const newData = {
      perpage,
      page,
      search,
    };
    const url = `${config.apiserver}/admin/users/`;

    const user = await Axios.post(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getUsersChart = async (token) => {
  try {
    const url = `${config.apiserver}/app/userschart/`;

    const user = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return user.data;
  } catch (err) {
    return returnError(err);
  }
};
export const addUserToGroup = async (newUserGroup, token) => {
  try {
    const url = `${config.apiserver}/admin/addusertogroup/`;
    const result = await Axios.put(url, newUserGroup, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

//#region Riders
export const getRiders = async (newData, token) => {
  try {
    const url = `${config.apiserver}/users/getRiders/`;
    const result = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateRider = async (newData, token) => {
  try {
    const url = `${config.apiserver}/users/updateRider/`;
    const result = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const storeRider = async (newData, token) => {
  try {
    const url = `${config.apiserver}/users/storeRider/`;
    const result = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
//#endregion

//#region Customers
export const getCustomers = async (newData, token) => {
  try {
    const url = `${config.apiserver}/users/getCustomers/`;
    const result = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateCustomer = async (newData, token) => {
  try {
    const url = `${config.apiserver}/users/updateCustomer/`;
    const result = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const storeCustomer = async (newData, token) => {
  try {
    const url = `${config.apiserver}/users/storeCustomer/`;
    const result = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
//#endregion

//#region Managers
export const getManagers = async (newData, token) => {
  try {
    const url = `${config.apiserver}/users/getManagers/`;
    const result = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const updateManager = async (newData, token) => {
  try {
    const url = `${config.apiserver}/users/updateManager/`;
    const result = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const storeManager = async (newData, token) => {
  try {
    const url = `${config.apiserver}/users/storeManager/`;
    const result = await Axios.put(url, newData, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log("00000000", result);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

//#endregion
